import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { INotification } from "../../../Managers/Types";
import { Modal } from "../../../Components/Modal";
import { getUserDateFormat, showAppModal, showSnackbar } from "../../../AppState";
import { measurementTransform } from "../../../Managers/MeasurementService";
import { alertConditionTransform } from "../../../Managers/AlertConditionService";
import { unitsTransform } from "../../../Managers/UnitsService";
import { ILineChartValue, ISetPoint, TimeSeriesLineChart } from "../../../Components";
import { getDeviceSensorData, getNameSlug, prepareChartDataSet } from "../../../Managers";
import "../AlertsHistory.scss";
import { useTranslation } from "react-i18next";
import { isBatteryLowAlert } from "../../../Managers/AlertService";
import { getTimeFormatValueForUser } from "../../../Enums/TimeFormat";
import { styleAcknowledgementNotes } from "../../../Managers/NotificationService";

interface Props {
  selectedAlert: INotification;
}

const AlertsHistoryDetailsModal: React.FC<Props> = ({ selectedAlert }) => {
  const [graphData, setGraphData] = useState<ILineChartValue[]>([]);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [setPoints, setSetPoints] = useState<ISetPoint[]>([]);
  const notesRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(["alert_history", "common", "alerts"]);

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser();

  useEffect(() => {
    initData(selectedAlert);
    scrollToBottom();
  }, [selectedAlert]);

  const styledAcknowledgeNotes = styleAcknowledgementNotes(selectedAlert.acknowledgement_notes);

  const initData = (selectedAlert: INotification) => {
    if (selectedAlert) {
      const startDate = moment(selectedAlert.createdAt).subtract(5, "days").toDate();
      const endDate = moment(selectedAlert.createdAt).add(5, "days").toDate();
      _getSensorData(selectedAlert, startDate, endDate);
    }
  };

  const _getSensorData = (selectedAlert: INotification, startDate: Date, endDate: Date) => {
    setIsGraphLoading(true);
    setGraphData([]);
    setSetPoints([]);

    if (!selectedAlert?.Sensor) {
      return;
    }

    getDeviceSensorData(selectedAlert.Sensor, startDate.toISOString(), endDate.toISOString(), false)
      .then((r) => {
        console.log("getDeviceSensorData");
        console.log(r);
        if (r && r.length) {
          const sensorData = prepareChartDataSet(r, selectedAlert.Sensor!, selectedAlert.Sensor?.is_imperial);
          setGraphData([...sensorData]);

          const point: ISetPoint[] = [
            {
              x: new Date(selectedAlert.createdAt),
              y: measurementTransform(selectedAlert.value.value, {
                unit: selectedAlert.Sensor?.default_unit,
                empirical: selectedAlert.Sensor?.is_imperial,
                type: selectedAlert.Sensor?.Sensor_type.type,
              }),
              _id: 1,
              unit: selectedAlert.Sensor?.default_unit ?? "",
              triggeredTarget: null,
            },
          ];

          setSetPoints(point);
        }
      })
      .catch((e) => {
        const errorMessage = t("alert_history:data_load_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => setIsGraphLoading(false));
  };

  const scrollToBottom = () => {
    if (notesRef.current) {
      notesRef.current.scrollTop = notesRef.current.scrollHeight;
    }
  };

  return (
    <Modal
      bodyClassName={"alert-details-body"}
      buttons={
        <button className="btn btn-info alerts-details-button" onClick={() => showAppModal(null)}>
          {t("common:cancel")}
        </button>
      }>
      <div className="alert-history-details-modal">
        <div className="top-wrapper">
          <div className="top-wrapper-left">
            {t("alert_history:device")} {selectedAlert?.Sensor?.Device?.name || selectedAlert?.Device?.name || ""}
          </div>
          <div className="top-wrapper-right">
            <div className="u-mobile-hide">
              {t("alert_history:placement")}: {selectedAlert?.Sensor?.Device?.location_note || selectedAlert?.Device?.location_note || "--"}
            </div>
            <div className="u-mobile-hide">
              {t("alert_history:serial_number")}:{" "}
              {selectedAlert?.Sensor?.Device?.serial_number || selectedAlert?.Device?.serial_number || ""}
            </div>
          </div>
        </div>

        <div className="alert-details">
          <div className="alert-detail-column u-desktop-hide">
            <div className="input-label">{t("alert_history:placement")}</div>
            <div>{selectedAlert?.Sensor?.Device?.location_note || selectedAlert?.Device?.location_note || "--"}</div>
          </div>

          <div className="alert-detail-column u-desktop-hide">
            <div className="input-label">{t("alert_history:serial_number")}</div>
            <div>{selectedAlert?.Sensor?.Device?.serial_number || selectedAlert?.Device?.serial_number || ""}</div>
          </div>

          <div className="alert-detail-column date-column">
            <div className="input-label">{t("alert_history:date")}</div>
            <div>{moment(selectedAlert?.createdAt).format(`${dateFormat} ${timeFormat}`)}</div>
          </div>

          <div className="alert-detail-column">
            <div className="input-label">{t("alert_history:sensor_type")}</div>
            <div>{selectedAlert.Sensor?.Sensor_type?.name}</div>
          </div>

          <div className="alert-detail-column alert-value-column">
            <div className="input-label">{t("alert_history:alert_value")}</div>
            {selectedAlert.Sensor !== null ? (
              <span>
                {measurementTransform(selectedAlert.value.value, {
                  unit: selectedAlert.Sensor?.default_unit || "degF",
                  empirical: selectedAlert.Sensor?.is_imperial,
                  type: selectedAlert.Sensor?.Sensor_type.type || "RANGE",
                })}
                {selectedAlert.Sensor?.Sensor_type.type !== "BOOLEAN" && (
                  <span>
                    {" "}
                    <span className="u-degree-history">
                      {unitsTransform(selectedAlert.Sensor?.default_unit || "degF", [
                        selectedAlert.Sensor?.default_unit || "degF",
                        selectedAlert.Sensor?.is_imperial,
                        selectedAlert.Sensor?.Sensor_type.type || "RANGE",
                      ])}
                    </span>{" "}
                  </span>
                )}
              </span>
            ) : selectedAlert.Device !== null ? (
              <span>
                {selectedAlert.value.value}
                <span>
                  <span className="u-degree-history">{isBatteryLowAlert(selectedAlert.Alert) && "V"}</span>
                </span>
              </span>
            ) : null}
          </div>

          <div className="alert-detail-column condition-column u-mobile-hide">
            <div className="input-label">{t("alert_history:alert_condition")}</div>
            <div>{alertConditionTransform(selectedAlert.Alert)}</div>
          </div>

          <div className="alert-detail-column">
            <div className="input-label">{t("alert_history:alert_type")}</div>
            <div>
              {selectedAlert.Sensor?.Sensor_type?.type
                ? t("alert_history:sensor_type", { context: getNameSlug(selectedAlert.Sensor?.Sensor_type?.type) })
                : isBatteryLowAlert(selectedAlert.Alert)
                ? t("alert_history:sensor_type_battery_alert")
                : ""}
            </div>
          </div>
        </div>

        <div className="alert-detail-column condition-column u-desktop-hide" style={{ padding: "0px 12px 15px 12px" }}>
          <div className="input-label">{t("alert_history:alert_condition")}</div>
          <div>{alertConditionTransform(selectedAlert.Alert)}</div>
        </div>

        <div className="alert-details">
          <div className="alert-detail-column">
            <label className="input-label">{t("alert_history:protocol")}</label>
            <div>{selectedAlert.Alert.protocol || "-"}</div>
          </div>
          <div className="alert-detail-column">
            <label className="input-label">{t("alert_history:resolved_notes")}</label>
            <div>{selectedAlert.resolved_notes || "-"}</div>
          </div>
        </div>

        <div className="notes-row">
          <label className="input-label">{t("alert_history:acknowledgement_notes")}</label>
          <div className="alert-details-notes-list custom-scrollbar" ref={notesRef}>
            {styledAcknowledgeNotes.map((note: string, index) => (
              <div key={index} className="alert-details-history-note" dangerouslySetInnerHTML={{ __html: note }}></div>
            ))}
            {!styledAcknowledgeNotes.length && "-"}
          </div>
        </div>

        {!isBatteryLowAlert(selectedAlert.Alert) &&
          (isGraphLoading ? (
            <div className="no-graph-data">{t("alert_history:loading_graph_data")}</div>
          ) : (
            <>
              {graphData.length > 0 ? (
                <TimeSeriesLineChart data={graphData} setPoints={setPoints} zoomEnabled={false} />
              ) : (
                <div className="no-graph-data">{t("alert_history:no_data_available")}</div>
              )}
            </>
          ))}
      </div>
    </Modal>
  );
};

export default AlertsHistoryDetailsModal;
