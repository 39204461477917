import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { ICON_TOOLTIP_DEFAULT_DELAY, LeftRail, StyledTooltip, useScreenMode } from "../../Components";
import { showAppModal } from "../../AppState";
import { mySearch } from "../../Managers";
import { WindowSize } from "../../Enums";
import { useTranslation } from "react-i18next";
import "./Watchlists.scss";
import { Drawer } from "@mui/material";
import { EditWatchlistModal } from "../Dashboard/Modals/EditWatchlistModal";
import { IWatchlist } from "../../Managers/Types";
import { SelectedWatchlistController } from "./SelectedWatchlistController";

interface IWatchlists {
  watchlists: IWatchlist[];
}

export const Watchlists: React.FC<IWatchlists> = observer(({ watchlists }) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchWatchlistString, setSearchWatchlistString] = useState("");
  const [isGraphLoading, setIsGraphLoading] = useState(true);

  const mode = useScreenMode();

  const sortedWatchlists = mySearch<IWatchlist>([...watchlists], searchWatchlistString).sort((a, b) =>
    order === "asc" ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
  );

  const [selectedWatchlist, setSelectedWatchlist] = useState<IWatchlist>(sortedWatchlists?.[0]);

  const { t } = useTranslation(["watchlists", "common"]);

  const refreshWatchlistsView = async (watchlist: IWatchlist) => {
    setIsGraphLoading(true);
    setSelectedWatchlist(watchlist);
  };

  const setupSelectedWatchlist = useCallback(
    async (watchlist?: IWatchlist) => {
      setIsGraphLoading(true);
      if (watchlist && watchlist._id !== selectedWatchlist?._id) {
        await refreshWatchlistsView(watchlist);
      }
    },
    [selectedWatchlist],
  );

  return (
    <>
      <Drawer
        variant={mode === WindowSize.DESKTOP ? "permanent" : "temporary"}
        hideBackdrop={mode === WindowSize.DESKTOP}
        onBackdropClick={() => mode === WindowSize.TABLET && setDrawerOpen(false)}
        open={drawerOpen || mode === WindowSize.DESKTOP}>
        <LeftRail
          header={
            <div className="input-holder u-full-width">
              <input
                type="text"
                className="input input-default"
                placeholder={t("watchlists:list.find_watchlist")}
                value={searchWatchlistString}
                onChange={(e) => setSearchWatchlistString(e.target.value)}
              />
              <i className="fa fa-search input-holder-icon" />
            </div>
          }
          body={
            <nav className="left-rail-nav">
              <div className="left-rail-nav-header">
                <h2 className="left-rail-nav-header-title mod-with-btn">
                  <StyledTooltip
                    title={t("common:sort_by_name", { context: order === "asc" ? "desc" : "asc" })}
                    enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                    <button className="btn btn-plain" onClick={() => setOrder(order === "asc" ? "desc" : "asc")}>
                      {order === "asc" ? <i className="fa fa-sort-amount-desc" /> : <i className="fa fa-sort-amount-asc" />}
                    </button>
                  </StyledTooltip>{" "}
                  {t("watchlists:list.left_rail.title")}
                </h2>

                <button className="btn btn-circle btn-primary" onClick={() => showAppModal(<EditWatchlistModal mode="new" />)}>
                  <i className="fa fa-plus" />
                  <span className="sr-only">{t("watchlists:list.add_watchlist")}</span>
                </button>
              </div>
              <ul className="left-rail-nav-group">
                {sortedWatchlists.length < 1 ? (
                  <li className="left-rail-nav-item">{t("watchlists:list.no_watchlists")}</li>
                ) : (
                  <>
                    {sortedWatchlists.map((watchlist) => (
                      <li
                        key={watchlist._id}
                        className={classNames("left-rail-nav-item", { active: watchlist._id === selectedWatchlist?._id })}>
                        <div onClick={() => setupSelectedWatchlist(watchlist)} title={watchlist.name} className="u-text-eclipse">
                          {watchlist.name}
                        </div>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </nav>
          }
        />
      </Drawer>

      <SelectedWatchlistController
        watchlists={sortedWatchlists}
        selectedWatchlist={selectedWatchlist}
        isGraphLoading={isGraphLoading}
        setIsGraphLoading={setIsGraphLoading}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        setupSelectedWatchlist={setupSelectedWatchlist}
        refreshWatchlistsView={refreshWatchlistsView}
      />
    </>
  );
});
