import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { IWatchlist } from "../../Managers/Types";
import {
  HeaderRow,
  ICON_TOOLTIP_DEFAULT_DELAY,
  ILineChartValue,
  ISetPoint,
  RangePicker,
  SelectInput,
  StyledTooltip,
  ZoomDates,
} from "../../Components";
import { LeftRailToggleIcon } from "../../icon";
import { showAppModal, showSnackbar } from "../../AppState";
import { EditWatchlistModal } from "../Dashboard/Modals/EditWatchlistModal";
import { ConfirmModal } from "../../Modals";
import classNames from "classnames";
import { GraphController } from "./GraphController";
import { WatchlistTable } from "./WatchlistTable";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled/macro";
import { destroyWatchlist } from "../../Managers/WatchlistsService";
import { isImperialUnit } from "../../Managers/UnitsService";
import { getNotificationsForWatchlist } from "../../Managers/NotificationService";
import moment from "moment";
import { measurementTransform } from "../../Managers/MeasurementService";
import { debounce, getDeviceSensorData, ISensorDatapoint, prepareChartDataSet } from "../../Managers";
import "./Watchlists.scss";

const shapes: Array<"square" | "circle" | "triangle" | "diamond"> = ["square", "circle", "triangle", "diamond"];

const colors = ["#00d1a4", "#fa2fb5", "#06bdf7", "#ffa216"];

interface ISelectedWatchlist {
  watchlists: IWatchlist[];
  selectedWatchlist: IWatchlist;
  isGraphLoading: boolean;
  setIsGraphLoading: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setupSelectedWatchlist: (watchlist?: IWatchlist) => Promise<void>;
  refreshWatchlistsView: (watchlist: IWatchlist) => Promise<void>;
}

export const SelectedWatchlist: React.FC<ISelectedWatchlist> = observer(
  ({
    watchlists,
    selectedWatchlist,
    isGraphLoading,
    setIsGraphLoading,
    drawerOpen,
    setDrawerOpen,
    setupSelectedWatchlist,
    refreshWatchlistsView,
  }) => {
    const [isSaving, setIsSaving] = useState("");
    const [setPoints, setSetPoints] = useState<ISetPoint[]>([]);
    const [graphData, setGraphData] = useState<ILineChartValue[]>([]);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(moment().subtract(24, "hours").toDate());
    const [visibleSensors, setVisibleSensors] = useState<Set<number>>(() => {
      const allSensorIds = selectedWatchlist.WatchlistSensors?.map((watchlistSensor) => watchlistSensor.Sensor._id);
      return new Set(allSensorIds);
    });

    const { t } = useTranslation(["watchlists", "common"]);

    const MobileHeaderRow = styled.div({
      display: "flex",
      gap: 16,
    });

    const atLeastOneSensorAvailable = selectedWatchlist
      ? selectedWatchlist.WatchlistSensors && selectedWatchlist.WatchlistSensors.length > 0
      : false;

    const removeWatchlist = () => {
      setIsSaving("watchlist-" + (selectedWatchlist?._id || 0));
      selectedWatchlist &&
        destroyWatchlist(selectedWatchlist?._id || 0)
          .then((r) => {
            console.log("deleted", r);
            showSnackbar(t("watchlists:list.watchlist_delete_success"), "success");
            showAppModal(null);
          })
          .catch((e) => {
            showSnackbar(t("watchlists:list.watchlist_delete_error", { name: selectedWatchlist?.name || "" }), "error");
            console.log("delete error", e);
          });
    };

    const _getSensorData = async (selectedWatchlist: IWatchlist, startDate: Date, endDate: Date) => {
      if (!selectedWatchlist?.WatchlistSensors || !selectedWatchlist.WatchlistSensors.length) {
        return;
      }

      setIsGraphLoading(true);
      setGraphData([]);
      setSetPoints([]);

      const isWatchlistImperial = isImperialUnit(selectedWatchlist.unit);
      const historicalData = await getNotificationsForWatchlist(
        moment(startDate).startOf("day").toISOString(),
        moment(endDate).endOf("day").toISOString(),
      );

      const allSetPoints = historicalData
        .filter((alert) => alert.Sensor && visibleSensors.has(alert.Sensor._id))
        .map((alert, index) => {
          return {
            x: new Date(alert.createdAt),
            y: measurementTransform(alert.value.value, {
              unit: alert.Sensor?.default_unit,
              empirical: isWatchlistImperial,
              type: alert.Sensor?.Sensor_type.type,
            }),
            _id: alert.Sensor?._id || Math.random(),
            unit: alert.Sensor?.default_unit ?? "",
            triggeredTarget: null,
            color: colors[index % colors.length],
            shape: shapes[index % shapes.length],
          };
        });

      const allSensorDataPromises = selectedWatchlist.WatchlistSensors.map((item, index) => {
        if (!visibleSensors.has(item.Sensor._id)) return Promise.resolve([]);

        return getDeviceSensorData(item.Sensor, startDate.toISOString(), endDate.toISOString(), false).then((response) => {
          response.forEach((setPoint: ISensorDatapoint) => {
            setPoint.color = colors[index % colors.length];
            setPoint.shape = shapes[index % shapes.length];
          });

          return response;
        });
      });

      Promise.all(allSensorDataPromises)
        .then((responses) => {
          const allSensorData = responses.flatMap((r, index) => {
            if (r && r.length) {
              const sensor = selectedWatchlist.WatchlistSensors![index].Sensor;
              return prepareChartDataSet(r, sensor, isWatchlistImperial);
            }
            return [];
          });

          setGraphData(allSensorData);
          setSetPoints(allSetPoints);
        })
        .catch((e) => {
          const errorMessage = t("watchlist:sensor_data_load_error");
          showSnackbar(errorMessage, "error");
          console.log(errorMessage, e);
        })
        .finally(() => {
          setIsGraphLoading(false);
        });
    };

    useEffect(() => {
      fetchData();
    }, [startDate, endDate, selectedWatchlist, visibleSensors]);

    const fetchData = useCallback(
      debounce(async () => {
        if (selectedWatchlist) {
          await _getSensorData(selectedWatchlist, startDate, endDate);
        }
      }, 500),
      [selectedWatchlist, startDate, endDate, visibleSensors],
    );

    const handleStartDateChange = (d: Date) => {
      setStartDate(d);
    };

    const handleEndDateChange = (d: Date) => {
      setEndDate(d);
    };

    const setDates = (start: Date, end: Date) => {
      handleStartDateChange(start);
      handleEndDateChange(end);
    };

    return (
      <main className="bapi-main mod-dashboard bapi-watchlists-dashboard">
        <HeaderRow
          className="u-mobile-hide"
          infoLeftSideControl={
            <div className="left-rail-toggle-wrapper watchlists-left-rail-icon-wrapper u-tablet-only">
              <button className="btn btn-icon" style={{ display: "flex" }} onClick={() => setDrawerOpen(!drawerOpen)}>
                <LeftRailToggleIcon />
              </button>
            </div>
          }
          info={{
            name: selectedWatchlist.name,
          }}
          fullWidth={false}
          infoControl={
            <>
              <div className="header-row-info-control">
                <StyledTooltip title={t("common:edit")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button
                    className="btn btn-icon"
                    onClick={() => {
                      showAppModal(<EditWatchlistModal mode={"edit"} watchlist={selectedWatchlist} />);
                    }}>
                    <i className="fa fa-edit u-text-teal mobile-tablet-icon" />
                    <span className="sr-only">{t("common:edit")}</span>
                  </button>
                </StyledTooltip>

                <StyledTooltip title={t("common:delete")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                  <button
                    className="btn btn-icon"
                    onClick={() => {
                      showAppModal(
                        <ConfirmModal
                          header={t("watchlists:details.remove")}
                          children={<p>{t("watchlists:details.confirm_delete", { name: selectedWatchlist?.name })}</p>}
                          confirmText={t("watchlists:details.remove")}
                          onConfirm={removeWatchlist}
                        />,
                      );
                    }}>
                    <i className="fa fa-trash u-text-teal mobile-tablet-icon" />
                    <span className="sr-only">{t("common:delete")}</span>
                  </button>
                </StyledTooltip>
              </div>
            </>
          }
        />

        <MobileHeaderRow className="header-row-watchlists-mobile-only u-mobile-only">
          <SelectInput
            label={t("watchlists:watchlist_list")}
            className="input-filter-holder"
            style={{ flex: 1, marginTop: "1rem" }}
            displayEmpty={true}
            menuItemClass="dark"
            inputClassName="dark"
            value={selectedWatchlist ? selectedWatchlist._id : ""}
            onChange={(watchlistId) => {
              const watchlist = watchlists.find((watchlist) => watchlist._id === watchlistId);
              if (watchlist) {
                setupSelectedWatchlist(watchlist);
              }
            }}
            options={[
              ...watchlists.map((watchlist) => ({
                value: watchlist._id,
                label: watchlist.name,
                key: watchlist?._id ? watchlist?._id.toString() : `${Math.random().toString(36).substring(2, 9)}`,
              })),
            ]}
          />
        </MobileHeaderRow>

        <section className="watchlists-mobile-first-section u-mobile-only">
          <div>
            <div className="watchlist-name">{selectedWatchlist?.name}</div>
          </div>

          <div className="header-row-info-control u-mobile-only">
            <StyledTooltip title={t("common:edit")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
              <button
                className="btn btn-icon"
                // TODO probably shouldn't be modal
                onClick={() => showAppModal(<EditWatchlistModal mode="edit" watchlist={selectedWatchlist} />)}>
                <i className="fa fa-edit u-text-teal mobile-tablet-icon" />
                <span className="sr-only">{t("common:edit")}</span>
              </button>
            </StyledTooltip>
            <StyledTooltip title={t("common:delete")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
              <button
                className="btn btn-icon"
                onClick={() => {
                  showAppModal(
                    <ConfirmModal
                      header={t("watchlists:details.remove")}
                      children={<p>{t("watchlists:details.confirm_delete", { name: selectedWatchlist?.name })}</p>}
                      confirmText={t("watchlists:details.remove")}
                      onConfirm={removeWatchlist}
                    />,
                  );
                }}>
                <i className="fa fa-trash u-text-teal mobile-tablet-icon" />
                <span className="sr-only">{t("common:delete")}</span>
              </button>
            </StyledTooltip>
          </div>
        </section>

        <section className={classNames("main-panel main-panel-watchlists")}>
          <div
            className="row"
            style={{
              display: "flex",
              marginTop: "7px",
              marginBottom: 0,
              padding: 0,
            }}>
            <div className="u-mobile-hide" style={{ marginBottom: 8, marginRight: 32, padding: 0 }}>
              <label htmlFor="zoom" className="input-label u-mobile-hide">
                {t("common:zoom")}
              </label>
              <ZoomDates onSelectOption={(start, end) => setDates(start, end)} optionsToHide={["3M"]} />
            </div>
            <div style={{ padding: 0 }}>
              <RangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                className="vertical"
                hasTimeSelector={true}
              />
            </div>
          </div>
          <div className="row u-mobile-only" style={{ textAlign: "center", marginTop: "1rem" }}>
            <label htmlFor="zoom" className="input-label">
              {t("common:zoom")}
            </label>
            <ZoomDates onSelectOption={(start, end) => setDates(start, end)} optionsToHide={["3M"]} />
          </div>

          <GraphController
            data={graphData}
            setPoints={setPoints}
            isLoading={isGraphLoading}
            atLeastOneSensorAvailable={!!atLeastOneSensorAvailable}
          />

          <WatchlistTable
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            refreshWatchlistsView={refreshWatchlistsView}
            watchlists={watchlists}
            selectedWatchlist={selectedWatchlist}
            visibleSensors={visibleSensors}
            setVisibleSensors={setVisibleSensors}
            atLeastOneSensorAvailable={!!atLeastOneSensorAvailable}
          />
        </section>
      </main>
    );
  },
);
