import { observer } from "mobx-react-lite";
import { Watchlists } from "./Watchlists";
import { useWatchlists } from "../../Managers/API";
import { Page, Wrapper } from "../../Components";
import "./Watchlists.scss";

export const WatchlistsController = observer(() => {
  const { data: watchlists, isFetching } = useWatchlists();

  if (isFetching || !watchlists) {
    return <></>;
  }

  return (
    <Page>
      <Wrapper>
        <Watchlists watchlists={watchlists} />
      </Wrapper>
    </Page>
  );
});
