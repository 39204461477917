// We just called it this to mimic the old system, to make it easier to cross-reference the code. The function is generic so callers can
// receive back the same array type they pass in. But internally the function handles search many types of input objects. We could have
// split this out to separate functions but it wasn't clear if the code would have any unions or unexpected types anywhere so this was
// an easy win.
import Moment from "moment-timezone";
import { OrderBy } from "../Enums";
import { getUserDateFormat } from "../AppState";
import { getTimeFormatValueForUser } from "../Enums/TimeFormat";

export const mySearch = <T>(list: T[], search: string, prop?: keyof T): T[] => {
  const term = search?.toLowerCase() || "";
  return list.filter((item: any) => {
    if (prop) {
      return item[prop].includes(search);
    }
    if (item.first_name || item.last_name) {
      // searching for users
      let name = `${item.first_name} ${item.last_name}`;

      return name.toLowerCase().indexOf(term) > -1 || name.toLowerCase().indexOf(term) > -1;
    } else if (item.location_note) {
      // searching for devices
      return (
        (item.name && item.name.toLowerCase().indexOf(term) > -1) ||
        (item.location_note && item.location_note.toLowerCase().indexOf(term) > -1)
      );
    } else if (item.name) {
      // searching for alerts and others
      return item.name.toLowerCase().indexOf(term) > -1;
    }

    return item;
  });
};

export const myOrder = <T>(list: T[], prop: keyof T, order: OrderBy = OrderBy.ASC) => {
  switch (typeof prop) {
    case "number":
      return list.sort((a, b) => {
        if (order === OrderBy.ASC) {
          return (a[prop] as number) - (b[prop] as number);
        } else {
          return (b[prop] as number) - (a[prop] as number);
        }
      });
    case "string":
      return list.sort((a, b) => {
        if (order === OrderBy.ASC) {
          return (a[prop] as string).toLowerCase() < (b[prop] as string).toLowerCase() ? -1 : 1;
        } else {
          return (a[prop] as string).toLowerCase() < (b[prop] as string).toLowerCase() ? 1 : -1;
        }
      });
    default:
      return list;
  }
};

export const myFilter = <T>(
  list: T[],
  filters: {
    prop: keyof T;
    value: boolean | boolean[] | string | string[] | number | number[] | Date | Date[];
  }[],
) => {
  return list.filter((item) =>
    filters.every((filter) => {
      if (Array.isArray(filter.value) && typeof item[filter.prop] !== "object") {
        return filter.value.some((v) => v === item[filter.prop]);
      }

      if (Array.isArray(filter.value) && filter.value.length === 2) {
        return item[filter.prop] > filter.value[0] && item[filter.prop] < filter.value[1];
      }

      return item[filter.prop] === filter.value || filter.value === undefined || filter.value === "";
    }),
  );
};
// TODO: Verify that this formats dates using the proper tokens
export const formatDate = (date: string | Date | null = new Date()) =>
  Moment.tz(date instanceof Date ? date.toISOString() : "" + date, Moment.tz.guess()).format(
    `${getTimeFormatValueForUser()} ${getUserDateFormat()} z`,
  );

export const utcDateToLocalDate = (date: Date) => new Date(date.toLocaleString());

export const formatDateCustom = (date: string | number | Date | null, format: string) => Moment(date).format(format);

// TODO: This is the accepted method but prints $ in front, the old system showed USD in front. Should we adjust?
const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
export const formatCurrency = (val: string | number | null | undefined) => currencyFormatter.format(+(val || 0));

export const myIcon = (value: string): string => {
  // Handle WAI channel designators (CH{1-4} - {Temperature|Range|Dry Contact}
  // This isn't great, but will work until a larger refactor happens in the sensor_disambiguator
  if (value.includes("Temperature")) {
    value = "Temperature";
  }
  if (value.includes("Range")) {
    value = "";
  }
  if (value.includes("Dry Contact") || value.includes("Contact")) {
    value = "Binary Input";
  }
  return value.toLowerCase().trim().split(" ").join("_");
};

export const capitalize = (value: string) =>
  value
    .split(" ")
    .map((s) => {
      if (s.length) {
        return String(s[0]).toUpperCase() + s.slice(1);
      }

      return s;
    })
    .join(" ");

export const myKeys = (value: any, args: string[]) => {
  const keys = Object.keys(value);
  if (args) {
    keys.sort((key1, key2) => {
      return key1.toLowerCase() > key2.toLowerCase() ? 1 : -1;
    });
  }

  return keys;
};

export const checkForWai418Devices = (serialNumber: string) => {
  return checkForWai418HumidityDevice(serialNumber) || checkForWai418TemperatureDevice(serialNumber);
};

export const checkForWai418HumidityDevice = (serialNumber: string) => {
  const wai418DeviceStringHumidity = process.env.REACT_APP_WAI_418_DEVICE_SERIALS_HUMIDITY ?? "";

  return wai418DeviceStringHumidity.split(" ").includes(serialNumber);
};

export const checkForWai418TemperatureDevice = (serialNumber: string) => {
  const wai418DeviceStringTemperature = process.env.REACT_APP_WAI_418_DEVICE_SERIALS_TEMPERATURE ?? "";

  return wai418DeviceStringTemperature.split(" ").includes(serialNumber);
};

export const getSupportEmail = () => process.env.REACT_APP_SUPPORT_EMAIL;

export const getNameSlug = (name: string, omitNumbers = false) =>
  name
    .replace(/[^a-z0-9_ ]/gim, " ")
    .split(" ")
    .filter((str) => {
      const isNotEmpty = str !== "";

      if (omitNumbers) {
        return isNotEmpty && Number.isNaN(Number(str));
      }

      return isNotEmpty;
    })
    .join("_")
    .toLowerCase();

export const parseToSignificantDigits = (value: number, digits = 2) => {
  if (value > 1 || value < -1) {
    return value % 1 === 0 ? value : value.toFixed(digits);
  }

  return value.toPrecision(digits);
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const debounce = <T extends (...args: any[]) => void>(func: T, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const capitalizeFirstLetter = (val: string) => {
  if (val.length === 0) {
    return val;
  }

  return val.charAt(0).toUpperCase() + String(val).slice(1);
};
