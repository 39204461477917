import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AppState, setLoggedIn, showAppModal } from "../AppState";
import { isUserPermissionAllowed, PermissionEnum } from "../Enums";
import { BillingInfo, ManageUserProfileModal } from "../Modals";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { HelpDropdown } from "../Views/HelpTabs/HelpDropdown";
import { URLS } from "../Views/URLS";
import { ManageLocationsModal } from "../Modals/ManageLocationsModal";

export const AccountMenu: React.FC = observer(() => {
  const [showHelp, setShowHelp] = useState(false);
  const { t } = useTranslation(["account_menu", "legal"]);
  const navigate = useNavigate();

  const countryCode = AppState.user?.country_code ?? "US";

  const menuItems = [
    { title: t("account_menu:manage_profile"), action: () => showAppModal(<ManageUserProfileModal />) },
    {
      title: t("account_menu:manage_location"),
      action: () => showAppModal(<ManageLocationsModal />),
      hide: !isUserPermissionAllowed(PermissionEnum.EDIT_LOCATION),
    },
    {
      title: t("account_menu:subscription_info"),
      action: () => showAppModal(<BillingInfo />),
      hide:
        !isUserPermissionAllowed([PermissionEnum.SUBSCRIPTION_ACCESS, PermissionEnum.BILLING_ACCESS]) ?? AppState.user?.Account?.is_demo,
    },
    {
      className: "separated desktop-hide submenu-trigger",
      icon: "fa fa-caret-right",
      title: t("account_menu:help"),
      action: () => setShowHelp(true),
    },
    {
      className: "separated",
      title: t("legal:terms"),
      action: () => navigate(URLS.TermsOfService.replace(":country", countryCode)),
    },
    { title: t("legal:privacy"), action: () => navigate(URLS.PrivacyPolicy.replace(":country", countryCode)) },
    { className: "separated", title: t("account_menu:log_out"), action: () => setLoggedIn(false, null, null) },
    { className: "non-click separated", title: t("account_menu:app_version") },
  ];

  return showHelp ? (
    <HelpDropdown
      className="dropdown-submenu"
      hide={() => setShowHelp(false)}
      header={
        <div className="dropdown-submenu-header">
          <button className="btn btn-plain" style={{ display: "flex" }} onClick={() => setShowHelp(false)}>
            <i className="fa fa-caret-left" />
          </button>
          <span className="dropdown-submenu-header-title">{t("account_menu:help")}</span>
          <span />
        </div>
      }
    />
  ) : (
    <section role="menu" className="dropdown-menu dropdown-menu-right">
      <ul style={{ paddingLeft: 0 }}>
        {menuItems.map((item) =>
          item.hide ? null : (
            <li key={item.title} role="menuitem" className={classNames("dropdown-menu-item", item.className)} onClick={item.action}>
              <span>{item.title}</span>
              {item.icon ? <i className={item.icon} /> : null}
            </li>
          ),
        )}
      </ul>
    </section>
  );
});
