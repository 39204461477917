import moment from "moment";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Stack, Tabs } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ErrorBoundary, FormFieldDate, FormFieldSelect, ILineChartValue, Tab, useScreenMode, ZoomDates } from "../../../../Components";
import {
  checkForWai418Devices,
  checkForWai418HumidityDevice,
  checkForWai418TemperatureDevice,
  formatDate,
  getDeviceSensorData,
  getNameSlug,
  myIcon,
  prepareChartDataSet,
  toggleSensorDisplayOnDashboard,
  updateSensor,
} from "../../../../Managers";
import * as Measurements from "../../../../Managers/MeasurementService";
import { measurementTransform } from "../../../../Managers/MeasurementService";
import { unitsTransform } from "../../../../Managers/UnitsService";
import { EditAlertsList } from "../EditAlertsList";
import { AppState, showAppModal, showSnackbar } from "../../../../AppState";
import { AddAlertModal } from "../AddAlertModal";
import "./DeviceDetailModal.scss";
import { ConfirmModal } from "../../../../Modals";
import { deleteSensorAlerts } from "../../../../Managers/DeviceAlertService";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../Components/Modal";
import { OrderBy, WindowSize } from "../../../../Enums";
import { IDevice, ISensor, ISensorDataNote } from "../../../../Managers/Types";
import { AddToWatchlistModal } from "../AddToWatchlistModal";
import { NotesSection } from "./NotesSection";
import { ChartController } from "./ChartController";
import * as yup from "yup";
import { DeviceDetailModalDeviceLayer } from "./DeviceDetailModalDeviceLayer";
import useFirstRender from "../../../../hooks/useFirstRender";

const DAYS_IN_MONTH = 31;

interface IDeviceDetailProps {
  device: IDevice;
  sensorTabIndex: number;
  setSensorTabIndex: React.Dispatch<React.SetStateAction<number>>;
  sensors: Array<ISensor>;
  refresh?: (order?: OrderBy, sort?: string) => void;
}

export const DeviceDetailModal: React.FC<IDeviceDetailProps> = observer(
  ({ device, sensorTabIndex, setSensorTabIndex, sensors, refresh }) => {
    const [chartData, setChartData] = useState<ILineChartValue[]>([]);
    const [isGraphLoading, setIsGraphLoading] = useState(true);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(moment().subtract(24, "hours").toDate());
    const [noteMode, setNoteMode] = useState<boolean>(false);
    const [selectedNote, setSelectedNote] = useState<ISensorDataNote>();

    const validationSchema = yup.object({
      startDate: yup.string(),
      endDate: yup.string(),
      isImperial: yup.boolean().oneOf([true, false]),
      showOnDashboard: yup.boolean().oneOf([true, false]),
    });

    const mode = useScreenMode();

    const { t } = useTranslation(["dashboard", "sensor_types", "common"]);
    const hasTimeSelector = true;

    const minDate = new Date(device?.createdAt || "");
    const selectedSensor = sensors?.[sensorTabIndex];

    // moved this, so we can check for wai418 devices even if the graphs fail to load the latest data
    // i.e. if the device hasn't reported in some time, this will still convert the values.
    const convertToHumidity = device?.serial_number ? checkForWai418HumidityDevice(device.serial_number) : false;
    const convertToTemperature = device?.serial_number ? checkForWai418TemperatureDevice(device.serial_number) : false;

    const isWai418 = checkForWai418Devices(device.serial_number);

    const isFirstRender = useFirstRender();

    // resets date range when selected sensor is changed
    useEffect(() => {
      if (isFirstRender) {
        return;
      }

      setEndDate(new Date());
      setStartDate(moment().subtract(24, "hours").toDate());
    }, [sensorTabIndex]);

    useEffect(() => {
      if (!selectedSensor) {
        return;
      }

      setIsGraphLoading(true);
      let startTime = startDate.toISOString();
      let endTime = endDate.toISOString();

      getDeviceSensorData(selectedSensor, startTime, endTime, hasTimeSelector)
        .then((r) => {
          if (r && r.length) {
            const processed = prepareChartDataSet(r, selectedSensor, selectedSensor.is_imperial, convertToHumidity, convertToTemperature);
            setChartData(processed);
          }
        })
        .catch((e) => {
          const errorMessage = "Error loading sensor data";
          showSnackbar(errorMessage, "error");
          console.log(errorMessage, e);
        })
        .finally(() => {
          setIsGraphLoading(false);
        });
    }, [startDate, endDate, convertToHumidity, convertToTemperature]);

    const returnToDeviceDetailModal = () => {
      showAppModal(<DeviceDetailModalDeviceLayer deviceId={device._id} sensorId={selectedSensor._id} refresh={refresh} />);
    };

    const addAlert = (alerts: any[], sensorTypeId: number) => {
      showAppModal(<AddAlertModal sensorId={selectedSensor._id} deviceId={device._id} alerts={alerts} sensorTypeId={sensorTypeId} />);
    };

    const handleRemoveAllAlertsForSensor = (sensorId: number) =>
      deleteSensorAlerts(device._id, sensorId)
        .then(() => showSnackbar(t("dashboard:device_detail.remove_alerts_success"), "success"))
        .catch((e) => {
          const errorMessage = t("dashboard:device_detail.remove_alerts_error");
          showSnackbar(errorMessage, "error");
          console.warn(errorMessage, e);
        })
        .finally(() => {
          showAppModal(<DeviceDetailModalDeviceLayer deviceId={device._id} sensorId={selectedSensor._id} />);
        });

    const removeAllAlertsForSensor = (alerts: any[], sensorId: number) => {
      showAppModal(
        <ConfirmModal
          header={t("dashboard:device_detail.remove_all_alerts")}
          children={<p>{t("dashboard:device_detail.remove_all_alerts_confirm", { count: alerts.length })}</p>}
          confirmText={t("dashboard:device_detail.remove_all_alerts")}
          onConfirm={() => handleRemoveAllAlertsForSensor(sensorId)}
          onCancel={returnToDeviceDetailModal}
        />,
      );
    };

    // TODO: Slice the data set by start/end date.
    const initialValues = {
      startDate,
      endDate,
      isImperial: selectedSensor?.is_imperial ?? true,
      showOnDashboard:
        AppState?.user?._id && selectedSensor && selectedSensor.hide_on_dashboard_for_user_ids !== undefined
          ? !selectedSensor?.hide_on_dashboard_for_user_ids.includes(AppState.user._id)
          : true ?? true,
    };

    const setDates = (start: Date, end: Date, setFieldValue: any) => {
      handleStartDateChange(start, setFieldValue);
      handleEndDateChange(end, setFieldValue);
    };

    const handleStartDateChange = (d: Date, setFieldValue: any) => {
      setStartDate(d);
      setFieldValue("startDate", d);
    };

    const handleEndDateChange = (d: Date, setFieldValue: any) => {
      setEndDate(d);
      setFieldValue("endDate", d);
    };

    const toggleSensorVisibility = async (setFieldValue: any, currentVisibility: boolean) => {
      if (selectedSensor) {
        const userId = AppState.user!._id;
        const visibleSensors = device.Sensors.filter((sensor: ISensor) => !sensor.hide_on_dashboard_for_user_ids?.includes(userId));
        const previousValue = !selectedSensor.hide_on_dashboard_for_user_ids?.includes(userId);

        if (!currentVisibility && visibleSensors && visibleSensors.length <= 1) {
          showSnackbar(t("dashboard:device_detail.cannot_hide_last_sensor"), "warning");
          setTimeout(() => {
            setFieldValue("showOnDashboard", previousValue);
          }, 1);
          if (refresh) {
            refresh();
          }
          return;
        }

        try {
          await toggleSensorDisplayOnDashboard(selectedSensor);
          showSnackbar(t("dashboard:device_detail.toggle_sensor_display"), "success");
          setFieldValue("showOnDashboard", currentVisibility);
          if (refresh) {
            refresh();
          }
        } catch (error) {
          console.error(error);
          showSnackbar(t("dashboard:device_detail.toggle_sensor_error"), "error");
          setFieldValue("showOnDashboard", previousValue);
          initialValues.showOnDashboard = true;
        }
      }
    };

    const handleDisplayOnDashboardChange = async (v: any, setFieldValue: any) => {
      await toggleSensorVisibility(setFieldValue, v);
    };

    const isTemperature = useCallback(() => {
      const value = selectedSensor?.default_unit?.toLowerCase();
      return Measurements.getType(value).includes("temperature") || value === "kpa" || value === "kilopascal";
    }, [selectedSensor]);

    const getMeasurementOptions = useCallback(
      () =>
        [true, false].map((isImperial) => {
          return {
            value: isImperial,
            label: unitsTransform(selectedSensor?.default_unit || "degF", [
              selectedSensor?.default_unit || "degF",
              isImperial,
              selectedSensor?.Sensor_type.type,
            ]),
          };
        }),
      [isTemperature, selectedSensor],
    );

    const getDisplayOnDashboardOptions = () => {
      return [
        { label: "Show", value: true },
        { label: "Hide", value: false },
      ];
    };

    const handleUnitChange = useCallback(
      (isImperial: boolean, setFieldValue: any) => {
        setFieldValue("isImperial", isImperial);
        if (selectedSensor) {
          updateSensor(device, {
            ...selectedSensor,
            is_imperial: isImperial,
          }).then(() => (refresh ? refresh() : ""));
        }
      },
      [device, selectedSensor],
    );

    const handleAddNote = () => {
      setNoteMode(true);
    };

    const handleEditNote = (note: ISensorDataNote) => {
      setNoteMode(true);
      setSelectedNote(note);
    };

    const displaySensorReadingWithUnit = (selectedSensor: ISensor) => {
      if (selectedSensor.Sensor_type.type === "BOOLEAN") {
        return "";
      }

      return selectedSensor.display_unit !== "" && selectedSensor.display_unit !== selectedSensor.default_unit
        ? selectedSensor.display_unit
        : unitsTransform(selectedSensor.default_unit, [
            selectedSensor.default_unit,
            selectedSensor.is_imperial,
            selectedSensor.Sensor_type.type,
            convertToHumidity,
            convertToTemperature,
          ]);
    };

    const visibleAlerts = selectedSensor?.Alerts?.filter((a) => !a.hidden) ?? [];

    const showNotesSection = moment(endDate).diff(moment(startDate), "days") <= DAYS_IN_MONTH;

    const tabItems = sensors.map((sensor, index) => {
      const title = convertToHumidity
        ? t("sensor_types:humidity")
        : convertToTemperature
        ? t("sensor_types:temperature")
        : sensor.display_name || t(`sensor_types:${getNameSlug(sensor.Sensor_type.name)}`);

      const icon = (
        <span>
          {!isWai418 ? <i className={`icon icon-${myIcon(sensor.default_unit === "%" ? "set_point" : sensor.Sensor_type.name)}`} /> : null}
        </span>
      );
      return (
        <Tab
          value={index}
          label={
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              {icon}
              <span>{title}</span>
            </div>
          }
        />
      );
    });

    const title = (
      <>
        <h4 className="modal-title pull-left">{device.name}</h4>
        <span className="pull-right serial-number">
          {t("dashboard:device_detail.serial_number")}: {device.serial_number}
        </span>
      </>
    );

    return (
      <ErrorBoundary>
        <Formik
          key={selectedSensor?._id}
          initialValues={initialValues}
          onSubmit={() => {}}
          validationSchema={validationSchema}
          renableReinitialize={true}>
          {({ setFieldValue }) => (
            <Modal
              bodyClassName={"custom-scrollbar"}
              className="device-detail-modal modal-lg"
              buttons={
                <button className="btn btn-primary" onClick={() => showAppModal(null)}>
                  {t("common:close")}
                </button>
              }
              title={title}>
              <Tabs
                variant="scrollable"
                className="modal-tabs"
                scrollButtons={mode !== WindowSize.DESKTOP}
                allowScrollButtonsMobile
                value={sensorTabIndex}
                onChange={(_e, value) => setSensorTabIndex(value)}>
                {tabItems}
              </Tabs>
              {selectedSensor && (
                <div className="tab-pane active">
                  <div className="row">
                    <div className="row reading-row col-sm-4">
                      <div className="col-xs-12 col-sm-12">
                        <p className="reading-title">{t("dashboard:device_detail.last_reading")}</p>
                        <div className="holder reading">
                          <p className="reading-time">{formatDate(selectedSensor.last_report_time)}</p>

                          <h5 className="reading-value">
                            {measurementTransform(selectedSensor.current_value, {
                              unit: selectedSensor.default_unit,
                              empirical: selectedSensor.is_imperial,
                              type: selectedSensor.Sensor_type.type,
                              disableRounding: false,
                              convertToHumidity: convertToHumidity,
                              convertToTemperature: convertToTemperature,
                            })}
                            <span className="sub">{displaySensorReadingWithUnit(selectedSensor)}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row units-hide-row col-sm-4">
                      <div className="col-xs-6 col-sm-6 u-mobile-only">
                        <p className="reading-title">{t("dashboard:device_detail.show_on_dashboard")}</p>
                        <FormFieldSelect
                          options={getDisplayOnDashboardOptions()}
                          name="showOnDashboard"
                          className="mobile-unit"
                          onChange={(v) => handleDisplayOnDashboardChange(v, setFieldValue)}
                        />
                      </div>

                      {isTemperature() && (
                        <div className="col-xs-4 col-sm-4 u-mobile-only">
                          <p className="reading-title">{t("dashboard:device_detail.measurement")}</p>
                          <FormFieldSelect
                            options={getMeasurementOptions()}
                            name="isImperial"
                            className="mobile-unit"
                            onChange={(v) => handleUnitChange(v, setFieldValue)}
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-12 col-sm-8">
                      <div className="alert-header">
                        <p className="reading-title pull-left">{t("dashboard:device_detail.edit_alerts")}</p>
                        <button
                          className="btn btn-plain pull-right add-alert-button u-text-teal"
                          onClick={() => addAlert(selectedSensor.Alerts, selectedSensor.SensorTypeId)}>
                          <i className="fa fa-plus-circle u-text-teal" />
                          <div className="u-text-teal">{t("dashboard:device_detail.add_alert")}</div>
                        </button>
                      </div>
                      <div className="alert-section">
                        {visibleAlerts.length > 0 ? (
                          <EditAlertsList alerts={visibleAlerts} deviceId={device._id} />
                        ) : (
                          <p>{t("dashboard:device_detail.no_alerts_configured")}</p>
                        )}
                      </div>
                      {visibleAlerts.length > 0 ? (
                        <button
                          style={{ padding: "4px 6px" }}
                          className="btn btn-sm btn-danger pull-right"
                          onClick={() => removeAllAlertsForSensor(visibleAlerts, selectedSensor._id)}>
                          {t("dashboard:device_detail.remove_all_alerts_button").toUpperCase()}
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row" style={{ margin: "7px 0px", marginLeft: "-15px" }}>
                    <FormFieldSelect
                      options={getDisplayOnDashboardOptions()}
                      name="showOnDashboard"
                      className="col-xs-6 col-sm-3 u-mobile-hide"
                      label={t("dashboard:device_detail.show_on_dashboard")}
                      onChange={(v) => handleDisplayOnDashboardChange(v, setFieldValue)}
                    />
                    {isTemperature() && (
                      <FormFieldSelect
                        options={getMeasurementOptions()}
                        name="isImperial"
                        className="col-xs-6 col-sm-2 u-mobile-hide unit-select"
                        label={t("dashboard:device_detail.measurement")}
                        onChange={(v) => handleUnitChange(v, setFieldValue)}
                      />
                    )}
                  </div>

                  <Stack direction="column" className="u-mobile-only" gap="0.75em" paddingBottom="0.5em">
                    <button className="btn btn-secondary" onClick={() => showAppModal(<AddToWatchlistModal sensor={selectedSensor} />)}>
                      <span style={{ paddingRight: "0.5em" }}>{t("dashboard:device_detail.add_to_watchlist")}</span>
                      <i className="fa fa-plus-circle" />
                    </button>
                  </Stack>

                  <div className="row" style={{ marginTop: "8px", marginBottom: 0, paddingLeft: 4 }}>
                    <Stack direction="column" className="u-mobile-only" gap="0.25em" paddingBottom="0.25em">
                      <FormFieldDate
                        name="startDate"
                        minDate={minDate}
                        maxDate={endDate}
                        hasTimeSelector={hasTimeSelector}
                        className="col-xs-12 col-sm-12"
                        onChange={(d) => handleStartDateChange(d, setFieldValue)}
                        label={t("common:from")}
                      />

                      <FormFieldDate
                        name="endDate"
                        minDate={startDate}
                        maxDate={new Date()}
                        hasTimeSelector={hasTimeSelector}
                        className="col-xs-12 col-sm-12"
                        onChange={(d) => handleEndDateChange(d, setFieldValue)}
                        label={t("common:to")}
                      />
                    </Stack>

                    <div className="col-sm-3 col-xs-6 u-mobile-only" style={{ marginBottom: 8 }}>
                      <label htmlFor="zoom" className="input-label">
                        {t("common:zoom")}
                      </label>
                      <ZoomDates onSelectOption={(start, end) => setDates(start, end, setFieldValue)} />
                    </div>

                    <Stack direction="row" className="u-desktop-only" gap="2.5em" paddingBottom="0.25em" paddingLeft="0.75em">
                      <div>
                        <label htmlFor="zoom" className="input-label">
                          {t("common:zoom")}
                        </label>
                        <ZoomDates onSelectOption={(start, end) => setDates(start, end, setFieldValue)} />
                      </div>
                      <FormFieldDate
                        name="startDate"
                        minDate={minDate}
                        maxDate={endDate}
                        hasTimeSelector={hasTimeSelector}
                        onChange={(d) => handleStartDateChange(d, setFieldValue)}
                        label={t("common:from")}
                      />

                      <FormFieldDate
                        name="endDate"
                        minDate={startDate}
                        maxDate={new Date()}
                        hasTimeSelector={hasTimeSelector}
                        onChange={(d) => handleEndDateChange(d, setFieldValue)}
                        label={t("common:to")}
                      />
                      <div className="col-sm-2 col-xs-6 add-to-watchlist-column">
                        <div className="btn btn-primary" onClick={() => showAppModal(<AddToWatchlistModal sensor={selectedSensor} />)}>
                          <i className="fa fa-plus" />
                          <span>{t("dashboard:device_detail:add_to_watchlist")}</span>
                        </div>
                      </div>
                    </Stack>
                  </div>

                  {!isGraphLoading && chartData.length && (
                    <div className="u-text-small graph-break-note">{t("dashboard:device_detail.graph_break_note")}</div>
                  )}

                  <ChartController
                    noteMode={noteMode}
                    setNoteMode={setNoteMode}
                    selectedNote={selectedNote}
                    setSelectedNote={setSelectedNote}
                    isGraphLoading={isGraphLoading}
                    chartData={chartData}
                    convertToHumidity={convertToHumidity}
                    convertToTemperature={convertToTemperature}
                    selectedSensor={selectedSensor}
                  />

                  {showNotesSection && (
                    <NotesSection
                      sensorId={selectedSensor._id}
                      handleAddNote={handleAddNote}
                      handleEditNote={handleEditNote}
                      returnToDeviceDetailModal={returnToDeviceDetailModal}
                    />
                  )}
                </div>
              )}
            </Modal>
          )}
        </Formik>
      </ErrorBoundary>
    );
  },
);
