import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { observer } from "mobx-react-lite";
import { AppState, setSelectedLocation, showAppModal } from "../../AppState";
import { getLocations } from "../../Managers/API";
import { ILocation } from "../../Managers/Types";
import "./LocationDropdown.scss";
import { mySearch } from "../../Managers";
import { ICON_TOOLTIP_DEFAULT_DELAY, StyledTooltip } from "../../Components";
import { useTranslation } from "react-i18next";
import { isUserPermissionAllowed, PermissionEnum } from "../../Enums";
import { ManageLocationsModal } from "../../Modals/ManageLocationsModal";

const sortLocations = (locations: ILocation[], order: "asc" | "desc" = "asc") =>
  locations
    .slice()
    .sort((a, b) =>
      order === "asc" ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
    );

export const LocationDropDown: React.FC<{
  onChange?: (loc: ILocation) => void;
  selected?: ILocation;
  displayAllOption?: boolean;
}> = observer(({ onChange, displayAllOption, selected = AppState.selectedLocation }) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [locations, setLocations] = useState<ILocation[]>(sortLocations(AppState.user?.Locations ?? [], order));
  const [collapsed, setCollapsed] = useState(true);
  const [searchLocationString, setSearchLocationString] = useState("");
  const { t } = useTranslation("dashboard");

  useEffect(() => {
    if (isUserPermissionAllowed(PermissionEnum.EDIT_LOCATION)) {
      getLocations().then((res) => setLocations(sortLocations(res, order)));
    }
  }, []);

  useEffect(() => {
    setLocations(sortLocations(AppState.user?.Locations ?? [], order));
  }, [AppState.user?.Locations, order]);

  const selectLocation = (location: ILocation) => {
    if (onChange) {
      onChange(location);
    } else {
      window.localStorage.setItem("lastVisited", "" + location._id);
      setSelectedLocation(location);
    }
    setCollapsed(true);
  };

  if (AppState.selectedLocation == null && AppState.user?.Locations.length === 0 && locations.length > 0) {
    setSelectedLocation(locations[0]);
  }

  const sortedLocations = sortLocations(locations, order);
  const filteredLocations = sortedLocations.filter((location) => location.is_favorite);

  const searchedLocations = mySearch<ILocation>(filteredLocations, searchLocationString);

  return (
    <div className="location-dropdown">
      <div
        className={"dropdown-toggle"}
        onClick={() => {
          setCollapsed(!collapsed);
          setSearchLocationString("");
        }}>
        <h1 className="left-rail-title">
          {selected?.name}
          {!collapsed ? <i className="fa fa-angle-up" /> : <></>}
          {collapsed ? <i className="fa fa-angle-down" /> : <></>}
        </h1>
        <p className="left-rail-info">{selected?.address}</p>
      </div>

      <div className={classNames("dropdown-body", "custom-scrollbar", { "dropdown-expanded": !collapsed })}>
        <Collapse isOpened={!collapsed}>
          <div className="input-holder u-full-width find-location">
            <input
              type="text"
              className="input input-default"
              placeholder={t("dashboard:location_dropdown.find_location")}
              value={searchLocationString}
              onChange={(e) => setSearchLocationString(e.target.value)}
            />
            <i className="fa fa-search input-holder-icon" />
          </div>
          <div className="location-list-header mod-with-btn">
            <div className="view-all-locations" onClick={() => showAppModal(<ManageLocationsModal mode="favorites" />)}>
              <i className="fa fa-map-marker" />
              <span>{t("dashboard:location_dropdown.view_all_location")}</span>
            </div>
            <div className="location-list-order-row">
              <StyledTooltip
                title={"Sort by location name " + (order === "asc" ? "(descending)" : "(ascending)")}
                enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                <button className="btn btn-plain btn-location" onClick={() => setOrder(order === "desc" ? "asc" : "desc")}>
                  {order === "asc" ? <i className="fa fa-sort-amount-desc" /> : <i className="fa fa-sort-amount-asc" />}
                </button>
              </StyledTooltip>
              <div className="location-list-title">{t("dashboard:location_dropdown.favorites_list")}</div>
            </div>
          </div>
          {displayAllOption ? (
            <div key="all" className="dropdown-body-item" onClick={() => selectLocation({ name: t("common:all_locations") } as ILocation)}>
              <h1 className="left-rail-title">{t("common:all_locations")}</h1>
            </div>
          ) : null}
          {searchedLocations.length > 0 ? (
            searchedLocations.map((loc) => (
              <div
                key={loc._id}
                className={"dropdown-body-item" + (loc._id === AppState.selectedLocationId ? " selected " : "")}
                onClick={() => selectLocation(loc)}>
                <h1 className="left-rail-title">{loc.name}</h1>
                <p className="left-rail-info">{loc.address}</p>
              </div>
            ))
          ) : (
            <div className="no-results">{t("dashboard:location_dropdown.no_results")}</div>
          )}
        </Collapse>
      </div>
    </div>
  );
});
