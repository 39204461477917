import React, { useState } from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Colors } from "../Theme";
import classNames from "classnames";

interface IZoomDatesProps {
  onSelectOption: (start: Date, end: Date) => void;
  optionsToHide?: string[];
}

const ZoomButtonsWrapper = styled.div({
  display: "flex",
  justifyContent: "center",

  " > div": {
    backgroundColor: Colors.marineBlue,
    borderRadius: 4,
    padding: 4,
  },
});

export const ZoomDates: React.FC<IZoomDatesProps> = observer(({ onSelectOption, optionsToHide = [] }) => {
  const [selectedOption, setSelectedOption] = useState("1d");

  const setDates = (date: string) => {
    setSelectedOption(date);

    const start = moment()
      .subtract(Number(date[0]), date[1] as any)
      .toDate();
    const end = moment().toDate();
    onSelectOption(start, end);
  };

  const Content = () => (
    <div style={{ gap: 4, display: "flex", marginRight: "auto" }}>
      {["1d", "1w", "3M"]
        .filter((date) => !optionsToHide.includes(date))
        .map((date) => (
          <button
            key={date}
            className={classNames("btn btn-primary", { "btn-plain": date !== selectedOption })}
            onClick={() => setDates(date)}>
            {date.toUpperCase()}
          </button>
        ))}
    </div>
  );

  return (
    <ZoomButtonsWrapper>
      <Content />
    </ZoomButtonsWrapper>
  );
});
