import React from "react";
import { observer } from "mobx-react-lite";
import { PermissionBlocker, PlanDetails } from "../../Components";
import { AppState, showAppModal } from "../../AppState";
import { PermissionEnum } from "../../Enums";
import { UpdatePlanModal } from "../../Modals";
import { useAccountSubscriptionById } from "../../Managers";
import { IPlanProps } from "../../Managers/Types";
import { CalculatePrice } from "../../Managers/PaymentOptionsService";
import { IAccountInfo } from "./Types";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

interface ISubscriptionManagementTab {
  accountInfo: UseQueryResult<IAccountInfo | null>;
}

export const SubscriptionManagementTab: React.FC<ISubscriptionManagementTab> = observer(({ accountInfo }) => {
  const accountSubscription = useAccountSubscriptionById(AppState.selectedAccount?._id ?? 0);

  const { t } = useTranslation(["accounts", "billing_info"]);

  const calculatePrice = async (values: IPlanProps): Promise<number | null> => {
    if (!accountSubscription?.data) return null;

    const countryCode = accountInfo.data?.country_code ?? "US";
    const currencyCode = AppState.selectedAccount?.Currency?.iso_code ?? "USD";

    return await CalculatePrice(values, countryCode, currencyCode);
  };

  if (accountSubscription.isLoading) {
    return <i style={{ fontSize: "50px" }} className="fa fa-spinner fa-spin" />;
  }

  if (!accountSubscription.data) {
    return (
      <div className="no_stripe_integration">
        <span>{t("accounts:subscription.no_stripe_integration")}</span>
      </div>
    );
  }

  return (
    <>
      <PlanDetails accountSubscription={accountSubscription.data?.subscription} account={AppState.selectedAccount} updateByAdmin={true} />
      <PermissionBlocker permission={PermissionEnum.SUBSCRIPTION_ACCESS}>
        <button
          className="text-right btn btn-primary"
          onClick={() => {
            showAppModal(
              <UpdatePlanModal
                updateByAdmin={true}
                currentDevicesCount={accountSubscription?.data?.subscription.find((sub) => sub.plan === "numberOfDevices")?.used}
                currentUsersCount={accountSubscription?.data?.subscription.find((sub) => sub.plan === "numberOfUsers")?.used}
                calculateTotal={calculatePrice}
                updatingAccountInfo={accountInfo.data || undefined}
              />,
            );
          }}>
          {t("billing_info:subscription.update_plan")}
        </button>
      </PermissionBlocker>
    </>
  );
});
