import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, Drawer } from "@mui/material";
import { DashboardLeftRail } from "./Widgets";
import { useDeviceTypeGroups } from "../../Managers/API";
import {
  AppState,
  getDevices,
  setFetchedDevices,
  setGlobalSelectedGateway,
  setSelectedDeviceGroup,
  setSelectedLocation,
} from "../../AppState";
import { Page, useScreenMode, Wrapper } from "../../Components";
import { IDevice, IDeviceGroup, IGateway } from "../../Managers/Types";
import "./Dashboard.scss";
import { DeviceSortEnum, ISort, OrderBy, WindowSize } from "../../Enums";
import { useTranslation } from "react-i18next";
import { DashboardHeaderRow } from "./DashboardHeaderRow";
import { MobileDashboardHeader } from "./MobileDashboardHeader";
import { IDeviceFilter, useDashboardDevices } from "../../Managers/LocationService";
import useLocalStorage, { LocalStorageKey } from "../../hooks/useLocalStorage";
import { DashboardContentController } from "./DashboardContentController";

export const getDefaultDeviceFilter: () => IDeviceFilter = () => ({
  sensorType: [],
  deviceType: [],
  inAlert: [],
  offline: [],
  approaching: [],
  sampleInterval: [],
  transmitInterval: [],
});

export const getDefaultDeviceSort: () => ISort<DeviceSortEnum> = () => ({
  dir: OrderBy.ASC,
  prop: DeviceSortEnum.Name,
});

export const Dashboard: React.FC = observer(() => {
  const [selectedGateway, setSelectedGateway] = useState<IGateway | undefined | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<IDeviceGroup | undefined | null>(null);
  const [searchString, setSearchString] = useState("");
  const [deviceList, setDeviceList] = useState<Array<IDevice>>([]);
  const [showLoadButton, setShowLoadButton] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [mode, setMode] = useState<"gateways" | "groups">("gateways");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterOpen, setFiltersOpen] = useState(false);

  const { storedValue: deviceFilters, setStoredValue: setDeviceFilters } = useLocalStorage<IDeviceFilter>(
    LocalStorageKey.DASHBOARD_FILTERS,
    getDefaultDeviceFilter(),
  );
  const { storedValue: sort, setStoredValue: setSort } = useLocalStorage<ISort<DeviceSortEnum>>(
    LocalStorageKey.DASHBOARD_SORT,
    getDefaultDeviceSort(),
  );

  const { t } = useTranslation(["dashboard", "common"]);

  const deviceTypeGroups = useDeviceTypeGroups();
  const gateways = AppState.selectedLocation?.Gateways || [];
  const deviceGroups = AppState.selectedLocation?.Device_groups || [];
  const allDevices: IDevice[] = getDevices();
  const windowMode = useScreenMode();

  const dashboardDevicesQuery = useDashboardDevices(
    AppState.selectedLocation?._id || 0,
    selectedGateway?._id,
    selectedGroup?._id,
    sort,
    deviceFilters,
    currentPage,
    AppState.deviceLimit,
  );

  useEffect(() => {
    const gatewaysWithDevices = gateways.filter((el) => el.Devices.count);
    const previouslySelectedGateway = AppState.selectedGateway && gateways.find((g) => AppState.selectedGateway?._id === g._id);
    const r = previouslySelectedGateway ?? gatewaysWithDevices[0];

    setSelectedGateway(mode === "gateways" ? r : null);
    setSelectedGroup(mode === "groups" ? deviceGroups?.[0] : null);
  }, [AppState.selectedLocation?._id]);

  // This is done automatically by AppState post-login or session-load. There's nothing for us to do until it completes, so we exit early.
  if (deviceTypeGroups.isLoading) {
    console.log("[DASHBOARD] Awaiting full session load...");
    return (
      <Page>
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }

  useEffect(() => {
    dashboardDevicesQuery.refetch();
  }, [selectedGateway, selectedGroup, AppState.selectedLocation, currentPage]);

  useEffect(() => {
    if (dashboardDevicesQuery.data) {
      setFetchedDeviceList(dashboardDevicesQuery.data.rows);
    }
  }, [dashboardDevicesQuery.dataUpdatedAt]);

  useEffect(() => {
    const locationInterval = setInterval(() => {
      forceRefreshDevices(sort.dir, sort.prop);
    }, 30_000);

    return () => clearInterval(locationInterval);
  }, [selectedGateway, selectedGroup, currentPage, sort.dir, sort.prop]);

  useEffect(() => {
    const locationInterval = setInterval(() => {
      setSelectedLocation(AppState.selectedLocation!, false);
    }, 120_000);

    return () => clearInterval(locationInterval);
  }, [selectedGateway, selectedGroup, currentPage]);

  useEffect(() => {
    let currentDevices: IDevice[];

    if (selectedGateway) {
      currentDevices = allDevices.filter((device: any) => device.Gateways[0]._id === selectedGateway._id);
    } else if (selectedGroup) {
      currentDevices = [];
    } else {
      currentDevices = gateways.flatMap((g) => g.Devices.rows).slice(0, AppState.deviceLimit) || [];
    }

    setFetchedDeviceList(currentDevices);
  }, [AppState.selectedLocation]);

  const getSelectedCount = () => {
    if (selectedGroup) {
      return selectedGroup.Devices.length;
    } else if (selectedGateway) {
      return selectedGateway.Devices.count;
    }
    return gateways.reduce((a, b) => a + b.Devices.count, 0);
  };

  useEffect(() => {
    setShowLoadButton((currentPage + 1) * AppState.deviceLimit < getSelectedCount());
  }, [selectedGateway, selectedGroup, currentPage, getSelectedCount]);

  const getSelectedName = useCallback(() => {
    if (selectedGroup && selectedGroup.name) {
      return selectedGroup.name;
    } else if (selectedGateway) {
      return selectedGateway.name || t("dashboard:gateway_name") + " " + selectedGateway._id;
    }
    return t("dashboard:all_devices");
  }, [selectedGroup, selectedGateway, AppState.selectedLocation]);

  const forceRefreshDevices = useCallback((newSortDirection?: OrderBy, newSortProperty?: DeviceSortEnum) => {
    setSort({
      prop: newSortProperty ?? sort.prop,
      dir: newSortDirection ?? sort.dir,
    });

    dashboardDevicesQuery.refetch();
  }, []);

  const setFetchedDeviceList = useCallback((devices: Array<IDevice>) => {
    setFetchedDevices(devices);
    setDeviceList(devices);
  }, []);

  const loadMoreDevices = useCallback(() => {
    setCurrentPage((prev) => prev + 1);
  }, [setCurrentPage]);

  const clearFiltersCallback = () => {
    setFiltersOpen(false);
    setDeviceFilters(getDefaultDeviceFilter());
  };

  const selectGroup = useCallback(
    (group: IDeviceGroup | null, clearFilters: boolean) => {
      setCurrentPage(0);
      setSelectedDeviceGroup(group);
      setSelectedGroup(group);

      if (clearFilters) {
        clearFiltersCallback();
      }
    },
    [getSelectedCount],
  );

  const selectGateway = useCallback((gateway: IGateway | null, clearFilters: boolean) => {
    setCurrentPage(0);
    setGlobalSelectedGateway(gateway);
    setSelectedGateway(gateway);

    if (clearFilters) {
      clearFiltersCallback();
    }
  }, []);

  const dashboardLeftRail = (
    <DashboardLeftRail
      gateways={gateways || []}
      deviceGroups={deviceGroups}
      onSelectGateway={selectGateway}
      onSelectGroup={selectGroup}
      selectedGateway={selectedGateway}
      selectedGroup={selectedGroup}
    />
  );

  return (
    <Page>
      <Wrapper>
        {windowMode === WindowSize.DESKTOP ? dashboardLeftRail : null}
        {windowMode === WindowSize.TABLET ? (
          <Drawer onBackdropClick={() => setDrawerOpen(false)} open={drawerOpen}>
            {dashboardLeftRail}
          </Drawer>
        ) : null}

        <main className="bapi-main mod-dashboard page-dashboard-auto">
          {windowMode !== WindowSize.MOBILE ? (
            <DashboardHeaderRow
              sort={sort}
              setSort={setSort}
              name={getSelectedName()}
              deviceList={deviceList}
              selectGateway={selectGateway}
              selectedGateway={selectedGateway}
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              count={getSelectedCount()}
              searchString={searchString}
              setSearchString={setSearchString}
              setDrawerOpen={setDrawerOpen}
              setFiltersOpen={setFiltersOpen}
              filtersOpen={filterOpen}
              deviceFilters={deviceFilters}
              setDeviceFilters={setDeviceFilters}
            />
          ) : (
            <MobileDashboardHeader
              sort={sort}
              setSort={setSort}
              tab={mode}
              setTab={setMode}
              gateways={gateways}
              selectedGateway={selectedGateway}
              selectGateway={selectGateway}
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              groups={deviceGroups}
              searchString={searchString}
              setSearchString={setSearchString}
              deviceFilters={deviceFilters}
              setDeviceFilters={setDeviceFilters}
            />
          )}

          <DashboardContentController
            gateways={gateways}
            mode={mode}
            deviceList={deviceList}
            searchString={searchString}
            selectedGroup={selectedGroup}
            forceRefreshDevices={forceRefreshDevices}
            loadMoreDevices={loadMoreDevices}
            showLoadButton={showLoadButton}
          />
        </main>
      </Wrapper>
    </Page>
  );
});
