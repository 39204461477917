import { observer } from "mobx-react-lite";
import React from "react";
import { SelectedWatchlist } from "./SelectedWatchlist";
import { IWatchlist } from "../../Managers/Types";
import { HeaderRow, ICON_TOOLTIP_DEFAULT_DELAY, StyledTooltip } from "../../Components";
import classNames from "classnames";
import { showAppModal } from "../../AppState";
import { EditWatchlistModal } from "../Dashboard/Modals/EditWatchlistModal";
import { useTranslation } from "react-i18next";
import "./Watchlists.scss";

interface ISelectedWatchlistController {
  watchlists: IWatchlist[];
  selectedWatchlist?: IWatchlist;
  isGraphLoading: boolean;
  setIsGraphLoading: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setupSelectedWatchlist: (watchlist?: IWatchlist) => Promise<void>;
  refreshWatchlistsView: (watchlist: IWatchlist) => Promise<void>;
}

export const SelectedWatchlistController: React.FC<ISelectedWatchlistController> = observer(
  ({
    watchlists,
    selectedWatchlist,
    isGraphLoading,
    setIsGraphLoading,
    drawerOpen,
    setDrawerOpen,
    setupSelectedWatchlist,
    refreshWatchlistsView,
  }) => {
    const { t } = useTranslation(["watchlists"]);

    if (watchlists.length < 1) {
      return (
        <main className="bapi-main mod-dashboard bapi-no-watchlists-dashboard">
          <HeaderRow info={{ name: "" }} />
          <div className={classNames("main-panel no-watchlists-panel u-text-center")}>
            <p>{t("watchlists:list.no_watchlists")}</p>

            <StyledTooltip title={t("watchlists:list.create_watchlist")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
              <button className="btn btn-primary" onClick={() => showAppModal(<EditWatchlistModal mode="new" />)}>
                {t("watchlists:list.create_watchlist_button")}
              </button>
            </StyledTooltip>
          </div>
        </main>
      );
    }

    if (!selectedWatchlist) {
      return (
        <section className="main-panel no-results">
          <div className="setup-instruction" style={{ margin: "auto", width: "50%" }}>
            <i className="fa fa-info-circle" />
            <p>{t("watchlists:details.select_watchlist_instruction")}</p>
          </div>
        </section>
      );
    }

    // passing key to child component is antipattern but works as a quick solution.
    // When selectedWatchlist changes, component remounts
    return (
      <SelectedWatchlist
        key={selectedWatchlist._id}
        watchlists={watchlists}
        selectedWatchlist={selectedWatchlist}
        isGraphLoading={isGraphLoading}
        setIsGraphLoading={setIsGraphLoading}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        setupSelectedWatchlist={setupSelectedWatchlist}
        refreshWatchlistsView={refreshWatchlistsView}
      />
    );
  },
);
