import { useEffect, useState } from "react";
import { NOTIFICATION_REFETCH_INTERVAL, useLatestNotifications } from "../Managers/NotificationService";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../Managers";
import { measurementTransform } from "../Managers/MeasurementService";
import { unitsTransform } from "../Managers/UnitsService";

export default function useWebNotifications() {
  const { data: alerts } = useLatestNotifications();

  const [displayedWebNotificationIds, setDisplayedWebNotificationIds] = useState<Set<number>>(new Set());

  const { t } = useTranslation("alerts");

  useEffect(() => {
    const noAlerts = !alerts || alerts.length === 0;
    const shouldDisplayNotifications = "Notification" in window && Notification.permission === "granted";

    if (noAlerts || !shouldDisplayNotifications) {
      return;
    }

    const delta = NOTIFICATION_REFETCH_INTERVAL / 2;

    for (const alert of alerts) {
      const now = moment();
      const alertTime = moment(alert.createdAt);
      const millisecondsDiff = moment.duration(now.diff(alertTime)).asMilliseconds();

      const alertTimeValid = millisecondsDiff < NOTIFICATION_REFETCH_INTERVAL + delta;
      const alertAlreadyShown = displayedWebNotificationIds.has(alert._id);

      if (alertTimeValid && !alertAlreadyShown) {
        if (!alert.Sensor) {
          continue;
        }

        const title = capitalizeFirstLetter(alert.Alert.name);
        const sensorName = alert.Sensor.Sensor_type.name ?? "";
        const deviceName = alert.Sensor.Device?.name ?? "";
        const value = measurementTransform(alert.value.value, {
          unit: alert.Sensor.default_unit,
          empirical: alert.Sensor.is_imperial,
          type: alert.Sensor.Sensor_type.type,
        });

        const unit = unitsTransform(alert.Sensor.default_unit, [
          alert.Sensor.default_unit,
          alert.Sensor.is_imperial,
          alert.Sensor.Sensor_type.type,
        ]);

        const description = t("alerts:web_notification.notification_description", { sensorName, deviceName, value, unit });

        const notification = new Notification(title, {
          requireInteraction: true,
          body: description,
        });

        notification.onclick = () => {
          window.focus();
        };

        setDisplayedWebNotificationIds((prev) => prev.add(alert._id));
      }
    }
  }, [alerts]);
}
