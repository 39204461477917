import * as yup from "yup";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { showAppModal, showSnackbar } from "../../../AppState";
import { useWatchlists } from "../../../Managers/API";
import { ISensor, IWatchlist } from "../../../Managers/Types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";
import { CheckboxInput, StyledTooltip } from "../../../Components";
import { EditWatchlistModal } from "./EditWatchlistModal";
import "./AddEditWatchlistModal.scss";
import { addSensorToWatchlists, getWatchlistsBySensorId, removeSensorFromWatchlist } from "../../../Managers/WatchlistsService";

interface IAddToWatchlistProps {
  sensor: ISensor;
}

interface IInitialValues {
  watchlists: IWatchlist[];
}

const validationSchema = yup.object({
  watchlists: yup.array().of(
    yup.object().shape({
      _id: yup.string().required(),
      name: yup.string().required(),
      unit: yup.string(),
    }),
  ),
});

export const AddToWatchlistModal: React.FC<IAddToWatchlistProps> = observer(({ sensor }) => {
  const [selectedWatchlists, setSelectedWatchlists] = useState<IWatchlist[]>([]);
  const [initialValues, setInitialValues] = useState<IInitialValues>({ watchlists: [] });

  const watchlistsList = useWatchlists();
  const watchlists = watchlistsList.data?.filter((watchlist) => watchlist.SensorTypeId === sensor.SensorTypeId) ?? [];
  const { t } = useTranslation(["dashboard", "common"]);

  useEffect(() => {
    const setWatchlistsForSensor = async () => {
      const sensorWatchlists = await getWatchlistsBySensorId(sensor._id);
      setInitialValues({ watchlists: sensorWatchlists });
      setSelectedWatchlists(sensorWatchlists);
    };

    setWatchlistsForSensor();
  }, [sensor._id]);

  const handleAddToWatchlists = async (addingNewWatchlist: boolean = false) => {
    const previouslySelected = initialValues.watchlists.map((w) => w._id);
    const currentlySelected = selectedWatchlists.map((w) => w._id);

    const toAdd = currentlySelected.filter((watchlistId) => !previouslySelected.includes(watchlistId));
    const toRemove = previouslySelected.filter((watchlistId) => !currentlySelected.includes(watchlistId));

    try {
      if (toAdd.length > 0) {
        await addSensorToWatchlists(
          sensor,
          selectedWatchlists.filter((w) => toAdd.includes(w._id)),
        );
      }

      for (const watchlistId of toRemove) {
        if (watchlistId) {
          await removeSensorFromWatchlist(sensor, watchlistId);
        }
      }

      if (!addingNewWatchlist) {
        showSnackbar(t("dashboard:add_watchlist_modal.add_or_remove_from_watchlist_success"), "success");
      }
    } catch (error) {
      showSnackbar(t("dashboard:add_watchlist_modal.add_or_remove_from_watchlist_error"), "error");
      console.error("Error updating watchlists:", error);
    } finally {
      if (!addingNewWatchlist) {
        showAppModal(null);
      }
    }
  };

  const handleWatchlistSelect = (watchlist: IWatchlist) => {
    setSelectedWatchlists((prevSelected: IWatchlist[]) => {
      const updatedSelectedWatchlists = [...prevSelected];
      const watchlistIndex = updatedSelectedWatchlists.findIndex((w) => w._id === watchlist._id);

      if (watchlistIndex > -1) {
        updatedSelectedWatchlists.splice(watchlistIndex, 1);
      } else {
        updatedSelectedWatchlists.push(watchlist);
      }

      return updatedSelectedWatchlists;
    });
  };

  const processAddToWatchlistButtonActions = async () => {
    await handleAddToWatchlists(true);
    showAppModal(<EditWatchlistModal mode="new" sensor={sensor} />);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => handleAddToWatchlists()}>
      {({ isSubmitting, submitForm }) => {
        return (
          <Modal
            className="modal-md"
            title={t(`dashboard:add_to_watchlist_modal.title`)}
            buttons={
              <>
                <button type="button" className="btn btn-info" disabled={isSubmitting} onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>

                <button type="button" className="btn btn-primary" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save")}
                </button>
              </>
            }>
            <div className="add-to-watchlist-modal-content">
              <button
                className="btn btn-plain add-to-watchlist-button-wrapper u-text-teal"
                onClick={() => processAddToWatchlistButtonActions()}>
                <i className="fa fa-plus-circle u-text-teal" />
                <div className="u-text-teal">{t("dashboard:add_to_watchlist_modal.create_new_watchlist")}</div>
              </button>

              <div className="add-to-watchlist-list">
                {watchlists.map((watchlist: IWatchlist) => {
                  const maxSensorLimitExceeded = (watchlist.WatchlistSensors?.length ?? 0) >= 4;
                  const isSelected = selectedWatchlists.some((w) => w._id === watchlist._id);

                  return (
                    <li
                      className="left-rail-nav-item watchlist-item"
                      key={watchlist._id}
                      onClick={() => {
                        if (!maxSensorLimitExceeded) {
                          handleWatchlistSelect(watchlist);
                        }
                      }}>
                      <CheckboxInput
                        checked={isSelected}
                        disabled={maxSensorLimitExceeded}
                        name={`watchlist-${watchlist._id}`}
                        className="checkbox-teal"
                        onClick={() => {
                          if (!maxSensorLimitExceeded) {
                            handleWatchlistSelect(watchlist);
                          }
                        }}
                      />

                      <div className="watchlist-item-text">{watchlist.name}</div>

                      {maxSensorLimitExceeded && (
                        <StyledTooltip title={t("dashboard:add_to_watchlist_modal.too_many_sensors")}>
                          <i className="fa fa-info-circle" />
                        </StyledTooltip>
                      )}
                    </li>
                  );
                })}
                {watchlists.length <= 0 && (
                  <div className={"no-watchlists-for-sensor"}>{t("dashboard:add_to_watchlist_modal.no_watchlist_for_sensor")}</div>
                )}
              </div>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
});
