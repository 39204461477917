import React from "react";
import { observer } from "mobx-react-lite";
import "./DeviceReports.scss";
import { Page, Wrapper } from "../../Components";
import { getUserDateFormat, showAppModal } from "../../AppState";
import { DeviceReportModal } from "../../Modals/DeviceReport/DeviceReportModal";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { useTranslation } from "react-i18next";
import { formatDateCustom, usePresets, useReportHistories } from "../../Managers";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";

export const DeviceReports: React.FC = observer(() => {
  const { t } = useTranslation(["device_reports"]);

  const presets = usePresets();
  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser();

  const { data: reportsData, isLoading } = useReportHistories();

  const openTemplate = (templateName: string) => {
    showAppModal(<DeviceReportModal initialTemplateName={templateName} presets={presets} />);
  };

  return (
    <Page>
      <Wrapper>
        <div className="device-reports-container">
          <div className="header" style={{ marginBottom: 16 }}>
            <div className="header-row-title">{t("device_reports:past_reports")}</div>
            <button className="btn btn-primary" onClick={() => showAppModal(<DeviceReportModal presets={presets} />)}>
              {t("device_reports:create_new_report")}
            </button>
          </div>

          {isLoading ? (
            <div className="loading-indicator">
              <i className="fa fa-spin fa-spinner" />
            </div>
          ) : reportsData?.length ? (
            <div className="device-reports-table-wrapper">
              <div className="device-reports-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="20%">{t("device_reports:date")}</TableCell>
                      <TableCell width="30%">{t("device_reports:report_name")}</TableCell>
                      <TableCell width="10%">{t("device_reports:lot_code")}</TableCell>
                      <TableCell width="20%">{t("device_reports:product_name")}</TableCell>
                      <TableCell width="20%" className="template-name-column">
                        {t("device_reports:template_name")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportsData.map((report) => (
                      <TableRow key={report._id} className="device-reports-row" onClick={() => openTemplate(report.templateName)}>
                        <TableCell>
                          {report.createdAt ? formatDateCustom(report.createdAt, `${dateFormat} - ${timeFormat}`) : "00/00/0000 - 00:00 pm"}
                        </TableCell>
                        <TableCell>{report.name}</TableCell>
                        <TableCell>{report.lotCode}</TableCell>
                        <TableCell>{report.productCode}</TableCell>
                        <TableCell className="template-name-column">{report.templateName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          ) : (
            <div className="no-results">No results</div>
          )}
        </div>
      </Wrapper>
    </Page>
  );
});
