import jsPDF from "jspdf";
import { IChartDataProps } from "../../Modals/DeviceReport/DeviceReportModal";
import { TFunction } from "i18next";
import autoTable from "jspdf-autotable";
import { INotification } from "../Types";
import { getTimeFormatValueForUser } from "../../Enums/TimeFormat";
import { getUserDateFormat } from "../../AppState";
import { measurementTransform } from "../MeasurementService";
import { unitsTransform } from "../UnitsService";
import { alertConditionTransform } from "../AlertConditionService";
import { isGenericAlert } from "../AlertService";
import moment from "moment";
import { parseAcknowledgementNotes } from "../NotificationService";
import { IReportOptions } from "../../Modals/DeviceReport/ReportOptions";

export async function includeAlertHistoryInPDF(
  doc: jsPDF,
  deviceId: number,
  sensors: IChartDataProps[],
  t: TFunction,
  options: IReportOptions,
  notificationsHistory: Array<INotification>,
) {
  const { historicalHeaders, historicalData } = await prepareHistoricalData(
    notificationsHistory,
    sensors,
    Number(deviceId),
    t,
    !!options.include_alert_notes,
    !!options.include_alert_protocol,
    !!options.include_alert_acknowledgement_notes,
  );

  if (historicalData.length) {
    if (doc.getNumberOfPages() === 1) {
      doc.deletePage(1);
    }
    doc.addPage("a4", "landscape");
    doc.setFontSize(18);
    doc.text(t("export:historical"), 40, 40);
    autoTable(doc, {
      head: [historicalHeaders.map((h) => h.header)],
      body: historicalData,
      startY: 70,
      margin: {
        top: 60,
        bottom: 70,
        left: 35,
      },
    });
  }
}

export async function includeAlertHistoryInXLSX(
  deviceId: number,
  sensors: IChartDataProps[],
  t: TFunction,
  xlsxAlertHistoryData: (string | number | null)[][],
  options: IReportOptions,
  notificationsHistory: Array<INotification>,
) {
  const { historicalHeaders, historicalData } = await prepareHistoricalData(
    notificationsHistory,
    sensors,
    Number(deviceId),
    t,
    !!options.include_alert_notes,
    !!options.include_alert_protocol,
    !!options.include_alert_acknowledgement_notes,
  );
  xlsxAlertHistoryData.push([t("export:historical")]);
  xlsxAlertHistoryData.push(historicalHeaders.map((header) => header.header));
  xlsxAlertHistoryData.push(...historicalData);
}

const prepareHistoricalData = async (
  notificationHistory: Array<INotification>,
  sensors: IChartDataProps[],
  deviceId: number,
  t: TFunction,
  includeNotes: boolean,
  includeProtocol: boolean,
  includeAcknowledgementNotes: boolean,
) => {
  const timeFormat = getTimeFormatValueForUser();
  const dateFormat = getUserDateFormat();

  const headers = [
    { header: t("export:value"), dataKey: "value" },
    { header: t("export:name"), dataKey: "name" },
    { header: t("export:condition"), dataKey: "condition" },
    { header: t("export:placement"), dataKey: "placement" },
    { header: t("export:serial"), dataKey: "serial" },
    { header: t("export:last_updated"), dataKey: "lastUpdateAt" },
    { header: t("export:resolved"), dataKey: "resolved" },
  ];

  if (includeNotes) {
    headers.push({ header: t("export:alert_notes"), dataKey: "notes" });
  }

  if (includeNotes) {
    headers.push({ header: t("export:alert_protocol"), dataKey: "protocol" });
  }

  if (includeAcknowledgementNotes) {
    headers.push({ header: t("export:alert_acknowledgement_notes"), dataKey: "acknowledgement" });
  }

  const deviceSensors = sensors.filter((sensor) => sensor.sensor.DeviceId === deviceId);

  const parsedHistoricalData = notificationHistory
    .filter((alert) => deviceSensors.some((item) => item.sensor._id === alert.Sensor?._id))
    .map((alert) => {
      let value =
        String(
          measurementTransform(alert.value.value, {
            unit: alert.Sensor?.default_unit ?? "degF",
            empirical: alert.Sensor?.is_imperial,
            type: alert.Sensor?.Sensor_type.type,
          }),
        ) +
        unitsTransform(alert.Sensor?.default_unit ?? "degF", [
          alert.Sensor?.default_unit ?? "degF",
          alert.Sensor?.is_imperial,
          alert.Sensor?.Sensor_type.type,
        ]);

      let alertName = "";

      if (alert.Alert) {
        alertName = alertConditionTransform(alert.Alert);

        if (!isGenericAlert(alert.Alert)) {
          alertName = alert.Alert.name + " - " + alertName;
        }
      }

      const alertCondition = alert.Alert ? alertConditionTransform(alert.Alert) : "";
      const placement = alert.Sensor?.Device?.location_note ?? "--";
      const serialNumber = alert.Sensor?.Device?.serial_number ?? "";
      const lastUpdateAt = moment(alert.updatedAt).format(`${dateFormat} ${timeFormat}`);
      const isResolved = alert.is_resolved ? t("common:yes") : t("common:no");
      const notes = includeNotes ? alert.resolved_notes : "";
      const protocol = includeProtocol ? alert.Alert.protocol : "";
      const acknowledgementNotes = includeAcknowledgementNotes ? parseAcknowledgementNotes(alert.acknowledgement_notes, true) : undefined;

      const params = [value, alertName, alertCondition, placement, serialNumber, lastUpdateAt, isResolved];

      if (includeNotes) {
        params.push(notes ?? "");
      }
      if (includeProtocol) {
        params.push(protocol ?? "");
      }

      if (includeAcknowledgementNotes) {
        if (acknowledgementNotes !== undefined) {
          params.push(acknowledgementNotes.join("\n"));
        } else {
          params.push("");
        }
      }

      return params;
    });

  return { historicalHeaders: headers, historicalData: parsedHistoricalData };
};
