import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { getLocationWithUsers, toggleLocationFavorite } from "../Managers/LocationService";
import { ICON_TOOLTIP_DEFAULT_DELAY, SelectInput, StyledTooltip } from "../Components";
import { mySearch } from "../Managers";
import { ILocation, IManageLocationsFilters } from "../Managers/Types";
import { useLocations } from "../Managers/API";
import {
  AppState,
  refreshFavoriteLocation,
  showAppModal,
  showSnackbar,
  setSelectedLocation as setAppStateSelectedLocation,
  setSelectedLocation,
} from "../AppState";
import { Modal } from "../Components/Modal";
import { useTranslation } from "react-i18next";
import { PaginationButtons } from "../Components/PaginationButtons";
import { XIcon } from "@heroicons/react/solid";
import { AddEditLocationModal } from "./AddEditLocationModal";
import "./ManageLocationsModal.scss";

type ModeType = "manage" | "favorites";

interface ManageLocationsModalProps {
  mode?: ModeType;
}

export const ManageLocationsModal: React.FC<ManageLocationsModalProps> = observer(({ mode = "manage" }) => {
  const initialFilters: IManageLocationsFilters = {
    sortBy: "name",
    order: "asc",
    leftRailOrder: "asc",
    show: mode === "manage" ? 9 : 6,
  };

  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [searchLocationString, setSearchLocationString] = useState("");
  const [sortedLocations, setSortedLocations] = useState<ILocation[]>([]);
  const [sortedFavoriteLocations, setSortedFavoriteLocations] = useState<ILocation[]>([]);
  const [paginatedLocations, setPaginatedLocations] = useState<Array<ILocation>>([]);
  const [selectedFilters, setSelectedFilters] = useState<IManageLocationsFilters>({ ...initialFilters });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const locations = useLocations();

  const { t } = useTranslation(["locations", "common"]);

  useEffect(() => {
    if (selectedLocation === null && AppState.selectedLocation !== null) {
      setSelectedLocation(AppState.selectedLocation);
    }
  }, []);

  useEffect(() => {
    const sortedLocations = mySearch<ILocation>([...(locations.data ?? [])], searchLocationString);
    sortedLocations.sort((a, b) => {
      if (selectedFilters.sortBy === "favorites") {
        return selectedFilters.order === "asc"
          ? Number(b.is_favorite) - Number(a.is_favorite)
          : Number(a.is_favorite) - Number(b.is_favorite);
      } else {
        return selectedFilters.order === "asc"
          ? a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          : b.name.toLowerCase().localeCompare(a.name.toLowerCase());
      }
    });

    setSortedLocations(sortedLocations);
  }, [locations.data, searchLocationString, selectedFilters.order, selectedFilters.sortBy]);

  useEffect(() => {
    const sortedFavoriteLocations = (locations.data ?? [])
      .filter((location) => location.is_favorite)
      .sort((a, b) =>
        selectedFilters.leftRailOrder === "asc"
          ? a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          : b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
      );
    setSortedFavoriteLocations(sortedFavoriteLocations);
  }, [locations.data, selectedFilters.leftRailOrder]);

  useEffect(() => {
    updatePaginatedLocations();
  }, [currentPage, sortedLocations, selectedFilters.show]);

  const updatePaginatedLocations = () => {
    const startIndex = (currentPage - 1) * selectedFilters.show;
    const endIndex = Math.min(startIndex + selectedFilters.show, sortedLocations.length);
    const newPaginatedLocations = sortedLocations.slice(startIndex, endIndex);
    setPaginatedLocations(newPaginatedLocations);
    const pageCount = Math.ceil(sortedLocations.length / selectedFilters.show);
    setTotalPages(pageCount);
    const filteredLocations = newPaginatedLocations.slice(0, selectedFilters.show);
    setPaginatedLocations(filteredLocations);
  };

  const selectLocation = (l: ILocation | null) => {
    setIsSaving(false);
    getLocationWithUsers(l)
      .then((r) => {
        setSelectedLocation(r);
        setAppStateSelectedLocation(r);
      })
      .catch((e) => {
        console.log(e);
        showSnackbar(t("locations:load_error"), "error");
      });
  };

  const toggleFavorite = async (location: ILocation) => {
    setIsSaving(true);
    await toggleLocationFavorite(location)
      .then(async (r) => {
        console.log("Update result", r);
        setIsSaving(false);

        showSnackbar(t("locations:update_location_success", { name: location.name }), "success");
        refreshFavoriteLocation(r);
      })
      .catch((e) => {
        console.log(e);
        setIsSaving(false);
        showSnackbar(t("locations:update_location_error", { name: location.name }), "error");
      });
  };

  return (
    <Modal
      bodyClassName="locations-modal-body"
      title={mode === "favorites" ? t("locations:select_location") : t("locations:title")}
      footerClassName="locations-footer"
      titleCloseButton={
        <>
          <div style={{ flex: 1 }} />
          <button onClick={() => showAppModal(null)} style={{ background: "none", color: "white", border: "none" }}>
            <XIcon style={{ width: "18px", marginBottom: "-4px" }} />
          </button>
        </>
      }>
      <div className="location-modal-content-wrapper">
        {mode === "favorites" && (
          <div className="left-rail left-panel">
            <div className="left-rail-body">
              <nav className="left-rail-nav">
                <div className="left-rail-nav-header" style={{ flexDirection: "column" }}>
                  <div className="manage-locations-title-section">
                    <div style={{ display: "flex" }}>
                      <StyledTooltip
                        title={"Sort by location name " + (selectedFilters.leftRailOrder === "asc" ? "(descending)" : "(ascending)")}
                        enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                        <button
                          className="btn btn-plain btn-location"
                          onClick={() =>
                            setSelectedFilters((prevFilters) => ({
                              ...prevFilters,
                              leftRailOrder: prevFilters.leftRailOrder === "desc" ? "asc" : "desc", // Zmiana leftRailOrder na "asc" lub "desc"
                            }))
                          }
                          type="button">
                          {selectedFilters.leftRailOrder === "asc" ? (
                            <i className="fa fa-sort-amount-desc" />
                          ) : (
                            <i className="fa fa-sort-amount-asc" />
                          )}
                        </button>
                      </StyledTooltip>

                      <h5 className="left-rail-nav-header-title mod-with-btn">{t("locations:favorites_list")}</h5>
                    </div>
                  </div>
                </div>

                <ul className="left-rail-nav-group">
                  {sortedFavoriteLocations?.map((store) => (
                    <li
                      key={store._id}
                      className={classNames("left-rail-nav-item", {
                        active: selectedLocation !== null && selectedLocation?._id === store._id,
                      })}>
                      <div onClick={() => selectLocation(store)} className="left-rail-favorite-item">
                        {store.name || "Location " + store._id}
                        <span className="left-rail-nav-item-sub u-display-block"> {store.address}</span>
                      </div>
                      {store.is_favorite ? (
                        <i className="fa fa-star header-icon-favorite" onClick={() => toggleFavorite(store)} />
                      ) : (
                        <i className="fa fa-star-o header-icon-favorite" onClick={() => toggleFavorite(store)} />
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        )}

        <div className={classNames("main-content-locations", { "right-panel main": mode === "favorites" })}>
          <div className={classNames("manage-locations-header", { "manage-list": mode === "manage" })}>
            <SelectInput
              required={false}
              displayError={false}
              className="form-group input-select form-group input-select vertical vertical sort-by-select"
              label={t("common:sort_by")}
              onChange={(selectedOption) => {
                setSelectedFilters((prevFilters) => ({
                  ...prevFilters,
                  sortBy: selectedOption,
                }));
              }}
              options={[
                {
                  value: "name",
                  label: t("locations:location_sort_by_name"),
                  key: "location-value-name",
                },
                {
                  value: "favorites",
                  label: t("locations:location_sort_by_favorites"),
                  key: "location-value-favorites",
                },
              ]}
              value={selectedFilters.sortBy}
            />
            <SelectInput
              required={false}
              displayError={false}
              className="form-group input-select form-group input-select vertical vertical order-by-select"
              label={t("common:order")}
              onChange={(selectedOption) => {
                setSelectedFilters((prevFilters) => ({
                  ...prevFilters,
                  order: selectedOption,
                }));
              }}
              options={[
                {
                  value: "desc",
                  label: t("locations:location_order_desc"),
                  key: "location-value-desc",
                },
                {
                  value: "asc",
                  label: t("locations:location_order_asc"),
                  key: "location-value-asc",
                },
              ]}
              value={selectedFilters.order}
            />
            <div className="input-holder search-bar">
              <input
                type="text"
                className="input input-default"
                placeholder={t("locations:find")}
                onChange={(e) => setSearchLocationString(e.target.value)}
                disabled={!locations?.data?.length}
                value={searchLocationString}
              />
              <i className="fa fa-search input-holder-icon" />
            </div>
            {mode === "manage" && (
              <>
                <button className="btn btn-primary" onClick={() => showAppModal(<AddEditLocationModal />)}>
                  {t("locations:add_new_location")}
                </button>
                <div></div>
              </>
            )}
          </div>
          <div className="manage-locations-list-wrapper">
            <div className={classNames("manage-locations-list", { "favorite-list": mode === "favorites" })}>
              {paginatedLocations.map((location: ILocation) => (
                <div key={location._id} className={"location-card" + (location._id === AppState.selectedLocationId ? " selected" : "")}>
                  <div className="location-card-header">
                    {location.name}
                    {mode === "manage" && (
                      <i className="fa fa-edit header-icon" onClick={() => showAppModal(<AddEditLocationModal loc={location} />)} />
                    )}
                    {mode === "favorites" &&
                      (location.is_favorite ? (
                        <i className="fa fa-star header-icon-favorite" onClick={() => toggleFavorite(location)} />
                      ) : (
                        <i className="fa fa-star-o header-icon-favorite" onClick={() => toggleFavorite(location)} />
                      ))}
                  </div>
                  <div className="location-card-body custom-scrollbar" onClick={() => selectLocation(location)}>
                    <span className="location-address"> {location.address}</span>
                    <br />
                    {location.city} {location.state} {location.zip} <br />
                    {location.country} <br />
                    {location.phone} <br />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {paginatedLocations.length > 0 && (
            <div className="text-right pagination-text-showing">
              {t("locations:shown_locations", {
                from: (currentPage - 1) * selectedFilters.show + 1,
                to: Math.min(currentPage * selectedFilters.show, sortedLocations.length),
              })}
            </div>
          )}

          {paginatedLocations?.length > 0 ? (
            <div className="paginator-locations">
              <PaginationButtons onPageChange={setCurrentPage} pageCount={totalPages} />
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
});
