import React from "react";
import { observer } from "mobx-react-lite";
import { IUser } from "../../Managers/Types";
import { IAccountInfo } from "./Types";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

interface ISubscriptionManagementTab {
  accountInfo: UseQueryResult<IAccountInfo | null>;
}

export const AccountMembersTab: React.FC<ISubscriptionManagementTab> = observer(({ accountInfo }) => {
  const { t } = useTranslation(["common", "users", "accounts"]);

  if (accountInfo.isLoading) {
    return <i style={{ fontSize: "50px" }} className="fa fa-spinner fa-spin" />;
  }

  if (!accountInfo.data || accountInfo.data?.account_members.length === 0) {
    return (
      <div className="no_stripe_integration">
        <span>{t("accounts:account.no_members")}</span>
      </div>
    );
  }

  return (
    <div className="row account-members">
      <div className="col-sm-12">
        <ul className="select-group account-member-list">
          <li className="select-group-item header">
            <div className="row u-full-width">
              <div className="col-xs-2">{t("users:details.last_name")}</div>
              <div className="col-xs-2">{t("users:details.first_name")}</div>
              <div className="col-xs-2">{t("users:details.title")}</div>
              <div className="col-xs-2">{t("users:details.role")}</div>
              <div className="col-xs-2">{t("users:details.email")}</div>
            </div>
          </li>
          {(accountInfo?.data?.account_members || []).map((u: IUser) => (
            <li key={u._id} className="select-group-item">
              <div className="row u-full-width">
                <div className="col-xs-2">{u.last_name ?? "--"}</div>
                <div className="col-xs-2">{u.first_name ?? "--"}</div>
                <div className="col-xs-2">{u.title ?? "--"}</div>
                <div className="col-xs-2">{t(`common:${u.role.toLowerCase()}`) ?? "--"}</div>
                <div className="col-xs-2">{u.email ?? "--"}</div>
              </div>
            </li>
          ))}
        </ul>
        <br />
      </div>
    </div>
  );
});
