import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { ReportMode } from "./ReportModalState";
import { StyledTooltip, useScreenMode } from "../../Components";
import { WindowSize } from "../../Enums";

interface IReportTypePickerProps {
  mode?: ReportMode;
  changeType: (type: ReportMode) => void;
  presetEdit?: boolean;
}

export const ReportTypePicker: React.FC<IReportTypePickerProps> = ({ mode, changeType, presetEdit = false }) => {
  const { t } = useTranslation("export");
  const modes = Object.values(ReportMode);

  const screenMode = useScreenMode();

  return (
    <FormControl>
      <RadioGroup value={mode ?? modes[0]} onChange={(e) => changeType(e.target.value as ReportMode)}>
        {modes.map((m) => (
          <Stack key={m} direction="row" paddingBottom="0.5em">
            <FormControlLabel
              value={m}
              control={<Radio disabled={presetEdit ? m === ReportMode.TEMPLATE : false} />}
              label={t("export:type", { context: m.toLowerCase() })}
            />

            <StyledTooltip
              title={t("export:type_description", { context: m.toLowerCase() })}
              arrow
              placement={screenMode === WindowSize.DESKTOP ? "right" : "bottom-start"}>
              <Stack justifyContent="center">
                <i className="fa fa-info-circle"></i>
              </Stack>
            </StyledTooltip>
          </Stack>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
