import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, showAppModal, showSnackbar } from "../AppState";
import { Modal } from "../Components/Modal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { DatepickerDropdown } from "../Components";
import moment from "moment/moment";
import { changeAccountDemo } from "../Managers/AccountsService";
import { IAccount } from "../Views/Accounts/Types";

interface IDemoModeSwitchModalProps {
  header: string;
  confirmButtonType?: string;
  onCancel?: () => void;
  refetchAccounts: (accountId: number) => void;
}

export const DemoModeSwitchModal: React.FC<IDemoModeSwitchModalProps> = observer(
  ({ header, confirmButtonType = "btn-primary", onCancel, refetchAccounts }) => {
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(moment().add(30, "days").toDate());
    const [changeDemoInProgress, setChangeDemoInProgress] = useState<boolean>(false);

    const { t } = useTranslation(["accounts", "common"]);

    const isDemoMode = AppState.selectedAccount?.is_demo === true || false;

    const changeAccountDemoCallback = (newAccountTypeValue: boolean) => {
      setChangeDemoInProgress(true);
      if (AppState.selectedAccount?._id !== undefined) {
        changeAccountDemo(AppState.selectedAccount?._id, newAccountTypeValue, startDate, endDate)
          .then((r) => {
            if (r.is_demo === newAccountTypeValue) {
              showSnackbar(t("accounts:change_mode_success"), "success");
              showAppModal(null);
              setChangeDemoInProgress(false);
            } else {
              showSnackbar(t("accounts:change_mode_error"), "error");
              setChangeDemoInProgress(false);
            }
          })
          .catch(() => {
            showSnackbar(t("accounts:change_mode_error"), "error");
            setChangeDemoInProgress(false);
          })
          .finally(() => {
            setTimeout(() => {
              if (AppState.selectedAccount?._id) {
                refetchAccounts(AppState.selectedAccount?._id);
              }
            });
          });
      } else {
        console.error("AppState.selectedAccount._id is undefined");
      }
    };

    const handleSetStartDate = (date: Date) => {
      setStartDate(date);
      if (endDate < date) {
        setEndDate(moment(date).add(30, "day").toDate());
      }
    };

    return (
      <Modal
        className="modal-sm"
        title={header}
        buttons={
          <>
            <button
              disabled={changeDemoInProgress}
              className={classNames("btn", "btn-info")}
              onClick={() => {
                onCancel ? onCancel() : showAppModal(null);
              }}>
              {t("common:cancel")}
            </button>

            <button
              className={classNames("btn", confirmButtonType)}
              disabled={changeDemoInProgress}
              onClick={() => {
                changeAccountDemoCallback(!isDemoMode);
              }}>
              {t("common:confirm")}
            </button>
          </>
        }>
        <div className={classNames({ "demo-change-modal-wrapper": !isDemoMode })}>
          {!isDemoMode && (
            <>
              <>
                <div className="demo-date-picker-wrapper">
                  <label className="input-label">{t("accounts:demo_start_date")}</label>
                  <DatepickerDropdown selectedDate={startDate} minDate={new Date()} onSelectDate={(date) => handleSetStartDate(date)} />
                </div>
                <div className="demo-date-picker-wrapper">
                  <label className="input-label">{t("accounts:demo_end_date")}</label>
                  <DatepickerDropdown selectedDate={endDate} minDate={startDate} onSelectDate={(date) => setEndDate(date)} />
                </div>
              </>
            </>
          )}

          <p>{t("accounts:confirm_change_type", { context: isDemoMode ? "regular" : "demo" })}</p>
          <p className={"bordered-info-text bordered-info-text--warning demo-info-bordered-text"}>
            {t("accounts:info_change_mode_to", { context: isDemoMode ? "regular" : "demo" })}
          </p>

          {changeDemoInProgress ? <i className="fa fa-spin fa-spinner" /> : null}
        </div>
      </Modal>
    );
  },
);

export default DemoModeSwitchModal;
